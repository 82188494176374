import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useHeadingStyles = makeStyles((theme) => ({
  headingRoot: {
    color: ({ color }) => color,
    fontSize: ({ size }) => size,
    fontFamily: "'Source Serif Pro', serif",
    fontWeight: 600,
    textAlign: "left",
  },
}));

export default function Heading({ children, color, size }) {
  const classes = useHeadingStyles({ color, size });

  return (
    <Typography variant="h3" className={classes.headingRoot}>
      {children}
    </Typography>
  );
}
