import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core";

export default withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    fontFamily: "'Sarala', sans-serif",
    padding: "8px 40px",
    background: "transparent",
    minWidth: 122,
  },
}))(Button);

export const useButtonStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
