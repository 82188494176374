import {
  Box,
  Container, Grid,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../../../api/services";
import { changeMyPassword, updateMe } from "../../../../api/UsersAPI";
import {
  authDataChanged,
  authLogout,
} from "../../../../store/actions/AuthActions";
import Button, { useButtonStyles } from "../../../lib/Button";
import PageHeader from "../../../shared/PageHeader";
import { setMessage, setSuccessMessage as setSuccessMessageAction } from "../../../../store/actions/SnackbarActions";
import { RoleLabel } from "../../../lib/RoleLabel";
import { requestPasswordReset } from "../../../../api/AuthAPI";

const InputBox = withStyles({
  root: {
    margin: "10px 0",
  },
})(Box);

export default function Profile() {
  const { register, handleSubmit, errors } = useForm();
  const authData = useSelector(({ auth }) => auth.authData);
  const dispatch = useDispatch();

  const onChangeUserDataSubmit = (data) => {
    if (authData.role !== 'admin') delete data.email;
    updateMe(data).then(() => {
      dispatch(authDataChanged());
      dispatch(
        setSuccessMessageAction("Profile data was changed successfully")
      );
    });
  };

  const onLogout = () => {
    authService.removeToken();
    dispatch(authLogout());
  };

  return (
    <>
      <PageHeader left="Profile" />
      <Container>
        <Box justifyContent="center" margin="0 auto" maxWidth="400px" my={5}>
          <Typography variant="h4" gutterBottom>
            Profile Settings
          </Typography>
          <form onSubmit={handleSubmit(onChangeUserDataSubmit)}>
            <InputBox mt="155px">
              <TextField
                fullWidth
                disabled={authData.role !== "admin"}
                error={Boolean(errors.email)}
                type="email"
                id="email"
                name="email"
                label="Email"
                defaultValue={authData.email}
                inputRef={register({ required: true })}
                helperText={(errors.lastName && "This field is required") || ""}
              />
            </InputBox>

            <InputBox>
              <TextField
                fullWidth
                error={Boolean(errors.firstName)}
                id="firstName"
                name="firstName"
                label="First Name"
                defaultValue={authData.firstName}
                inputRef={register({ required: true })}
                helperText={(errors.lastName && "This field is required") || ""}
              />
            </InputBox>
            <InputBox>
              <TextField
                fullWidth
                error={Boolean(errors.lastName)}
                id="lastName"
                name="lastName"
                defaultValue={authData.lastName}
                label="Last Name"
                inputRef={register({ required: true })}
                helperText={(errors.lastName && "This field is required") || ""}
              />
            </InputBox>
            {authData.role !== "user" && (
              <Box display="flex" justifyContent="space-between" mb="10px">
                <Typography>Role</Typography>
                <Typography><RoleLabel role={authData.role} /></Typography>
              </Box>
            )}
            {authData.companyName && (
              <Box display="flex" justifyContent="space-between" mb="10px">
                <Typography>Partner</Typography>
                <Typography>{authData.companyName}</Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between">
              {/*<Button onClick={onLogout}>Logout</Button>*/}
              <Button type="submit">Save</Button>
            </Box>
          </form>
          <_PasswordSettings />
        </Box>
      </Container>
    </>
  );
}

const useValidFieldStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      color: "green",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "green",
    },
  },
});

function PasswordSettings({ email }) {
  const dispatch = useDispatch();
  const btnClasses = useButtonStyles();
  const [isResetRunning, setIsResetRunning] = useState(false);

  const onResetPassword = () => {
    if (isResetRunning) return;
    setIsResetRunning(true);
    requestPasswordReset(email, window.location.protocol + '//' + window.location.host + '/password-reset-complete/')
      .then(() => {
        setIsResetRunning(false);
        dispatch(setSuccessMessageAction("Password reset was requested successfully."));
      })
      .catch(() => {
        setIsResetRunning(false);
        dispatch(setMessage("Couldn't request password reset: something went wrong.", 'error'));
      });
  };

  return (
    <Box my={5}>
      <Grid item xs={12} md="auto">
        <Button
          disabled={isResetRunning}
          onClick={onResetPassword}
          className={btnClasses.root}
        >
          {isResetRunning ? 'Request password reset...' : 'Reset Password'}
        </Button>
      </Grid>
    </Box>
  );
}

function _PasswordSettings() {
  const { register, handleSubmit, errors } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const btnClasses = useButtonStyles();
  const dispatch = useDispatch();

  // Temporary error message
  useEffect(() => {
    let timerId;
    if (errorMessage) {
      timerId = setTimeout(() => setErrorMessage(null), 3000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [errorMessage]);

  // Temporary succes message
  useEffect(() => {
    let timerId;
    if (successMessage) {
      timerId = setTimeout(() => setSuccessMessage(null), 3000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [successMessage]);

  const onSubmit = (data, e) => {
    changeMyPassword(data.oldPassword, data.newPassword)
      .then(() => {
        setErrorMessage(null);
        // ? setSuccessMessage("Password was changed successfully");
        dispatch(
          setSuccessMessageAction("Password was changed successfully")
        );

        e.target.reset();
      })
      .catch(() => setErrorMessage("Incorrect old password"));
  };

  const validate = (newPass, repPass) => {
    return Boolean(newPass === repPass && newPass && repPass);
  };

  return (
    <Box my={5}>
      <Typography variant="h4" gutterBottom>
        Change Password
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.oldPassword)}
            type="password"
            id="oldPassword"
            name="oldPassword"
            label="Old Password"
            inputRef={register({ required: true })}
            helperText={(errors.oldPassword && "This field is required") || ""}
          />
        </InputBox>
        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.newPassword)}
            // classes={{
            //   root: validate(newPassword, repeatPassword)
            //     ? validationClasses.root
            //     : "",
            // }}
            type="password"
            id="newPassword"
            name="newPassword"
            label="New Password"
            inputRef={register({ required: true })}
            helperText={(errors.newPassword && "This field is required") || ""}
          />
        </InputBox>
        <InputBox>
          {/*<TextField*/}
          {/*  fullWidth*/}
          {/*  error={Boolean(errors.repeatPassword)}*/}
          {/*  classes={{*/}
          {/*    root: validate(newPassword, repeatPassword)*/}
          {/*      ? validationClasses.root*/}
          {/*      : "",*/}
          {/*  }}*/}
          {/*  type="password"*/}
          {/*  id="repeatPassword"*/}
          {/*  name="repeatPassword"*/}
          {/*  label="Repeat Password"*/}
          {/*  inputRef={register({ required: true })}*/}
          {/*  helperText={*/}
          {/*    (errors.repeatPassword && "This field is required") || ""*/}
          {/*  }*/}
          {/*/>*/}
          <Box my="20px">
            <Button type="submit" className={btnClasses.root}>
              Change password
            </Button>
          </Box>

          {/*<Typography color="error">*/}
          {/*  {!validate(newPassword, repeatPassword) &&*/}
          {/*    newPassword &&*/}
          {/*    repeatPassword &&*/}
          {/*    "New Password & Repeat Password fields does not equal"}*/}
          {/*</Typography>*/}

          <Box height="30px">
            <Typography color="error">{errorMessage}</Typography>
            <Typography style={{ color: "green" }}>{successMessage}</Typography>
          </Box>
        </InputBox>
      </form>
    </Box>
  );
}
