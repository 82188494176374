/**
 * @module @components/shared/Files/Tree
 * @description The Tree component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, makeStyles, Button } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    minHeight: '100%',
    background: theme.palette.grey['100'],
    // borderRight: `1px solid ${theme.palette.divider}`,
    '& .MuiTreeItem-label': {
      paddingTop: theme.typography.pxToRem(3),
      paddingBottom: theme.typography.pxToRem(3),
    }
  },
  paper: {
    background: 'transparent',
  },
  iconToggle: {
    fontSize: theme.typography.pxToRem(25) + ' !important',
  },
  iconEnd: {
    fontSize: theme.typography.pxToRem(10) + ' !important',
  },
}));

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const Tree = ({ handleClickFolder, items, expanded, handleExpand, selected, handleGoRoot }) => {
  const classes = useStyles();

  const folders = items.filter(el => el.type === 'folder');
  function buildTree(node) {
    return {
      ...node,
      children: folders.filter(el => el.parent === node.id).map(buildTree),
    }
  }
  const tree = folders.filter(el => el.parent === null).map(buildTree);

  const renderTree = (tree) => {
    return tree.map(el => (
      <TreeItem
        onLabelClick={(e) => {
          e.preventDefault();
          handleClickFolder(el.url, el.id);
        }}
        onIconClick={() => {
          if (!el.childLoaded) {
            handleClickFolder(el.url, el.id);
          }
        }}
        key={el.id}
        nodeId={el.id}
        endIcon={(el.childLoaded ? !!el.children.length : true) ?
          <ChevronRightIcon className={classes.iconToggle} /> :
          <FiberManualRecordIcon className={classes.iconEnd} fontSize="small" />}
        label={
          <div>
            <Typography variant="body1">
              {el.viewName}
            </Typography>
          </div>
        }
      >
        {renderTree(el.children)}
      </TreeItem>
    ));
  };

  const handleSelect = (e, v) => {
    // let folder = folders.find(el => el.id === v);
    // handleClickFolder(folder.url, folder.id);
  };

  return (
    <Box className={classes.root}>
      <Paper elevation={0} square className={classes.paper}>
        <Box p={2}>
          <Box>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon className={classes.iconToggle} />}
              defaultExpanded={["root"]}
              defaultExpandIcon={<ChevronRightIcon className={classes.iconToggle} />}
              defaultEndIcon={<ChevronRightIcon className={classes.iconToggle} />}
              expanded={expanded}
              selected={selected}
              onNodeSelect={handleSelect}
              onNodeToggle={handleExpand}
            >
              <TreeItem
                onLabelClick={handleGoRoot}
                key="root"
                nodeId="root"
                label={
                  <div>
                    <Typography variant="body1">
                      Root
                    </Typography>
                  </div>
                }
              >
                {renderTree(tree)}
              </TreeItem>
            </TreeView>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

Tree.propTypes = {
  handleClickFolder: PropTypes.func,
  items: PropTypes.array,
  expanded: PropTypes.array,
  handleExpand: PropTypes.func,
  selected: PropTypes.string,
};

Tree.defaultProps = {

};

