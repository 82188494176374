import React from "react";
import { get as getUser } from "../../../../api/UsersAPI";
import { getList as getCompanies } from "../../../../api/CompaniesAPI";
import EditUser from "../../common/EditUser/EditUser";

export default function EditUserPage() {
  const initialUserData = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    company: "",
  };

  const fetchUserAndCompanies = async (isNewUser, userId) => {
    const userData = !isNewUser
      ? { password: "", ...(await getUser(userId)).data }
      : initialUserData;
    const companies = (await getCompanies()).data;

    return { userData, companyData: companies, isNewUser };
  };

  return <EditUser fetchUserData={fetchUserAndCompanies} />;
}
