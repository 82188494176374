/**
 * @module @components/shared/Users/UsersList/UsersList
 * @description The UsersList component.
 */

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Container, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useHistory } from "react-router";

import { RoleLabel } from "../../../lib/RoleLabel";
import { ActionLink, RouteLink } from "../../../lib/Link";
import PopupConfirm from "../../PopupConfirm";
import Button, { useButtonStyles } from "../../../lib/Button";
import DataLoader from "../../DataLoader";
import { getUsersAsync, removeUserAsync } from "../../../../store/actions/UsersActions";
import { getCompaniesAsync } from "../../../../store/actions/CompaniesActions";

const StyledCell = withStyles({
  root: {
    fontFamily: "'Montserrat', sans-serif",
    color: "black",
    fontWeight: 600,
    tableLayout: "auto",
    width: "18%",
  },
})(TableCell);

const useTableStyles = makeStyles({
  row: {
    "&:hover": {
      background: "rgba(189, 189, 189, 0.2)",
    },
  },
});

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const UsersList = ({ company = null }) => {
  const [userIdForDeleting, setUserIdForDeleting] = useState(null),
    currentUser = useSelector(({ auth }) => auth.authData),
    history = useHistory(),
    btnClasses = useButtonStyles(),
    dispatch = useDispatch(),
    usersState = useSelector(s => s.users),
    companiesList = useSelector(({ companies }) => companies.companiesData),
    tableClasses = useTableStyles();

  useEffect(() => {
    if (currentUser.role === 'admin' && !companiesList) dispatch(getCompaniesAsync());
    dispatch(getUsersAsync());
  }, []);

  const onPopupCancel = () => {
    setUserIdForDeleting(null);
  };

  const onPopupAgreement = () => {
    dispatch(removeUserAsync(userIdForDeleting));
    setUserIdForDeleting(null);
  };

  return usersState.usersData === null || usersState.isUsersLoading || (currentUser.role === 'admin' && !companiesList) ? (
    <DataLoader />
  ) : usersState.usersData === false ? (
    <Typography variant="h6" color="error">
      Something went wrong.
    </Typography>
  ) : usersState.usersData.length ? (
    <>
      <Box display="flex" flexDirection="row-reverse" mb={3}>
        <Button
          onClick={() =>
            history.push("/users/edit/new-user", {
              company: currentUser.company || company,
              companyName: currentUser.companyName,
              cancelTo: history.location.pathname,
            })
          }
          className={btnClasses.root}
        >
          Add a new user
        </Button>
      </Box>
      <Box my={3}>
        <TableContainer>
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledCell align="left">Email</StyledCell>
                <StyledCell align="left">Name</StyledCell>
                {currentUser.role === 'admin' ? (
                  <Fragment>
                    <StyledCell align="left">CoolIT Users</StyledCell>
                    <StyledCell align="left">Partner Users</StyledCell>
                    <StyledCell align="left">Partner</StyledCell>
                  </Fragment>
                ) : (
                  <Fragment>
                    <StyledCell align="left">Role</StyledCell>
                  </Fragment>
                )}
                <StyledCell align="right">Actions</StyledCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersState.usersData.filter(el => company !== null ? el.company === company : true).map((user) => (
                <TableRow className={tableClasses.row} key={user.id}>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">{user.fullName}</TableCell>
                  {currentUser.role === 'admin' ? (
                    <Fragment>
                      <TableCell align="left">{user.role === 'admin' ? <RoleLabel role={user.role} /> : null}</TableCell>
                      <TableCell align="left">{user.role !== 'admin' ? <RoleLabel role={user.role} /> : null}</TableCell>
                      <TableCell align="left">{companiesList.find(el => el.id === user.company)?.name}</TableCell>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <TableCell align="left">{user.role !== 'admin' ? <RoleLabel role={user.role} /> : null}</TableCell>
                    </Fragment>
                  )}
                  <TableCell align="right">
                    {user.id === currentUser.id ? (
                      "-"
                    ) : (
                      <>
                        {(currentUser.role === "manager"
                          ? user.role === "user"
                          : true) && (
                          <RouteLink to={`/users/edit/${user.id}/`}>
                            Edit
                          </RouteLink>
                        )}
                        {(currentUser.role === "manager"
                          ? user.role === "user"
                          : true) && (
                          <ActionLink
                            onClick={() => setUserIdForDeleting(user.id)}
                          >
                            Delete
                          </ActionLink>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PopupConfirm
        open={Boolean(userIdForDeleting)}
        title="Delete Confirmation"
        content="You have selected to delete this user."
        onCancel={onPopupCancel}
        onAgreement={onPopupAgreement}
      />
    </>
  ) : (
    <Box my="20px">
      <Typography variant="h5">No users in this partner.</Typography>
    </Box>
  );
};

UsersList.propTypes = {
  company: PropTypes.number,
};

UsersList.defaultProps = {};
