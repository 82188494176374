import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from "@material-ui/core";
import * as EmailValidator from 'email-validator';
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { update, create, remove } from "../../../../api/UsersAPI";
import Button, { useButtonStyles } from "../../../lib/Button";
import DataLoader from "../../../shared/DataLoader";
import PageHeader from "../../../shared/PageHeader";
import PopupConfirm from "../../../shared/PopupConfirm";
import filterObject from "../../../../utils/filterObject";
import { COOLIT_COMPANY_NAME } from "../../../../config";
import { setSuccessMessage, setMessage } from "../../../../store/actions/SnackbarActions";
import { RoleLabel } from "../../../lib/RoleLabel";
import { requestPasswordReset} from "../../../../api/AuthAPI";

const updateUserFunc = (userId, onUpdate, onError) => (data) => {
  const formData =
    data.password === "" ? filterObject(data, "password") : { ...data };
  update(userId, { company: null, ...formData })
    .then(() => onUpdate())
    .catch(onError);
};

const createUserFunc = (onCreating, onError) => (data) => {
  const formData =
    data.password === "" ? filterObject(data, "password") : { ...data };
  create({ company: null, ...formData, })
    .then(() => onCreating())
    .catch(onError);
};

export default function EditUser({ fetchUserData }) {
  const [userData, setUserData] = useState(null);
  const { params } = useRouteMatch();
  const history = useHistory();
  const authData = useSelector(({ auth }) => auth.authData);
  const historyState = history.location.state || {};

  let cancelTo = historyState.cancelTo || "/users/",
    companyID = historyState.company || null,
    companyName = historyState.companyName || null;

  useEffect(() => {
    let isFetching = true;

    if (params.id === "new-user" || !params.id) {
      fetchUserData(true).then((data) => isFetching && setUserData(data));
    } else if (parseInt(params.id) === authData.id) {
      history.push("/profile/");
    } else {
      fetchUserData(false, params.id).then(
        (data) => isFetching && setUserData(data)
      );
    }

    return () => {
      isFetching = false;
    };
  }, []);

  return (
    <Fragment>
      <PageHeader
        left={`${params.id === "new-user" ? "Add New" : "Edit"} User`}
        right={companyName || authData.companyName || COOLIT_COMPANY_NAME}
      />
      {userData ? (
        <Container>
          <EditUserForm
            cancelTo={cancelTo}
            userData={userData.userData}
            companyData={userData.companyData}
            isNewUser={userData.isNewUser}
            authData={authData}
          />
        </Container>
      ) : (
        <DataLoader />
      )}
    </Fragment>
  );
}

const InputBox = withStyles({
  root: {
    margin: "10px 0",
  },
})(Box);

function EditUserForm({
  userData,
  companyData,
  isNewUser,
  authData,
  cancelTo,
}) {
  const [open, toggle] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, control, errors, watch } = useForm();
  const btnClasses = useButtonStyles();
  const dispatch = useDispatch();
  const [sendErrors, setSendErrors] = useState([]);
  const [isResetRunning, setIsResetRunning] = useState(false);

  const roleValue = watch('role'),
    companyValue = watch('company');

  const onCancel = () => {
    toggle(false);
  };

  const onConfirm = () => {
    if (userData.id) {
      remove(userData.id).then(() => history.push("/users/"));
    }
  };

  const onSubmitSuccess = (message) => () => {
    history.push("/users/");
    dispatch(setSuccessMessage(message));
  };

  const onSubmitError = ({ response }) => {
    try {
      let errors = [];
      if (response.data instanceof Array) {
        setSendErrors([...response.data]);
      } else {
        for (let k in response.data) {
          errors = [...errors, ...response.data[k]];
        }
      }
      setSendErrors([...errors]);
    } catch (e) {
      setSendErrors(['Something went wrong.']);
    }
  };

  const onResetPassword = () => {
    if (isResetRunning) return;
    setIsResetRunning(true);
    requestPasswordReset(userData.email, window.location.protocol + '//' + window.location.host + '/password-reset-complete/')
      .then(() => {
        setIsResetRunning(false);
        dispatch(setSuccessMessage("Password reset was requested successfully."));
      })
      .catch(() => {
        setIsResetRunning(false);
        dispatch(setMessage("Couldn't request password reset: something went wrong.", 'error'));
      });
  };

  const onSubmit = isNewUser
    ? createUserFunc(onSubmitSuccess("User was created successfully"), onSubmitError)
    : updateUserFunc(
        userData.id,
        onSubmitSuccess("User was updated successfully"),
        onSubmitError,
      );

  const validateUserEmail = (value) => {
    if (!EmailValidator.validate(value)) return "Email is invalid.";

    let domains = null;
    if (!companyData) {
      domains = authData.companyDomains?.split(",").map(l => l.trim()) || null;
    } else {
      const company = companyData.find(l => l.id === companyValue);
      if (!company?.domains?.length) return true;
      domains = company.domains.split(",").map(l => l.trim());
    }

    if (!domains) return true;

    let domain = value.split("@")[1],
      isFound = false;

    for (let i = 0; i < domains.length; i++) {
      let val = domains[i];
      if (val === domain) {
        isFound = true;
        break;
      }
    }

    if (!isFound) return `Email have to belong to one of these domains: ${domains.join(", ")}.`;

    return true;
  };

  const shouldDisableEmail = () => {
    if (authData.role === "admin") {
      return isNewUser ? !companyValue : false;
    }
    return false;
  };

  const getEmailError = () => {
    return errors.email?.message;
  };

  const emailError = getEmailError();


  return (
    <Box mt={5} maxWidth="600px" margin="0 auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        {authData.role === "admin" ? roleValue !== 'admin' && (
          <FormControl fullWidth>
            <InputLabel id="company">Partner</InputLabel>
            <Controller
              name="company"
              rules={{ required: true }}
              defaultValue={userData.company}
              as={
                <Select
                  error={Boolean(errors.role)}
                  fullWidth
                  aria-label="Partner"
                  labelId="company"
                  id="company"
                  name="company"
                  label="Partner"
                >
                  {companyData.map((company) => (
                    <MenuItem value={company.id}>{company.name}</MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
          </FormControl>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            fontWeight="bold"
            mt="20px"
          >
            <Box>Partner</Box>
            <Box>{userData.companyName || authData.companyName}</Box>
            <input
              type="hidden"
              name="company"
              value={authData.company}
              ref={register()}
            />
          </Box>
        )}

        <InputBox>
          <TextField
            fullWidth
            error={!!emailError}
            id="email"
            type="email"
            name="email"
            label="Email"
            defaultValue={userData.email}
            disabled={shouldDisableEmail()}
            inputRef={register({ required: "This field is required", validate: { validateUserEmail } })}
            helperText={emailError}
          />
        </InputBox>

        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.firstName)}
            id="firstName"
            name="firstName"
            label="First Name"
            defaultValue={userData.firstName}
            inputRef={register({ required: true })}
            helperText={(errors.firstName && "This field is required") || ""}
          />
        </InputBox>
        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.lastName)}
            id="lastName"
            name="lastName"
            defaultValue={userData.lastName}
            label="Last Name"
            inputRef={register({ required: true })}
            helperText={(errors.lastName && "This field is required") || ""}
          />
        </InputBox>

        {/*<InputBox>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    error={Boolean(errors.password)}*/}
        {/*    id="password"*/}
        {/*    type="password"*/}
        {/*    name="password"*/}
        {/*    defaultValue={userData.password}*/}
        {/*    label="Password"*/}
        {/*    inputRef={register({ required: true })}*/}
        {/*    helperText={(errors.password && "This field is required") || ""}*/}
        {/*  />*/}
        {/*</InputBox>*/}

        {authData.role === "admin" ? (
          <InputBox>
            <FormControl fullWidth>
              <InputLabel id="role">Role</InputLabel>
              <Controller
                name="role"
                rules={{ required: true }}
                defaultValue={userData.role}
                as={
                  <Select
                    error={Boolean(errors.role)}
                    fullWidth
                    aria-label="Role"
                    labelId="role"
                    id="role"
                    name="role"
                    label="Role"
                  >
                    <MenuItem value="user"><RoleLabel role="user" /></MenuItem>
                    <MenuItem value="manager"><RoleLabel role="manager" /></MenuItem>
                    {/*<MenuItem value="admin"><RoleLabel role="admin" /></MenuItem>*/}
                  </Select>
                }
                control={control}
              />
            </FormControl>
          </InputBox>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            fontWeight="bold"
            mt="20px"
          >
            <Box>Role</Box>
            <Box>User</Box>
            <input type="hidden" name="role" value="user" ref={register()} />
          </Box>
        )}

        <Box mt="30px">
          <Grid container spacing={3}>
            {!isNewUser ? (
              <Grid item xs={12} md="auto">
                <Button
                  onClick={() => toggle(true)}
                  className={btnClasses.root}
                >
                  Delete User
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={6} md="auto">
              <Button
                onClick={() => history.push(cancelTo)}
                className={btnClasses.root}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} md="auto" className={btnClasses.root}>
              <Button type="submit" className={btnClasses.root}>
                {isNewUser ? 'Create' : 'Save'}
              </Button>
            </Grid>
            {!isNewUser ? (
              <Grid item xs={12} md="auto">
                <Button
                  disabled={isResetRunning}
                  onClick={onResetPassword}
                  className={btnClasses.root}
                >
                  {isResetRunning ? 'Request password reset...' : 'Reset Password'}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Box>

        {sendErrors.length > 0 && (
          <Box my={4}>
            <Alert severity="error">
              {sendErrors.join(' \n')}
            </Alert>
          </Box>
        )}
      </form>
      <PopupConfirm
        open={open}
        onCancel={onCancel}
        onAgreement={onConfirm}
        title="Delete Confirmation"
        content="You have selected to delete this user."
      />
    </Box>
  );
}
