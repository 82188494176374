/**
 * @module @components/pages/admin/CompanyView/CompanyDetails
 * @description The CompanyDetails component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from "@material-ui/core"
import Button from "../../../lib/Button";
import { useHistory } from "react-router";

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const CompanyDetails = ({ company }) => {
  const history = useHistory();
  let address = [company.address, company.address2, company.city, company.state, company.country, company.postalCode].filter(el => !!el).join(', ');

  return (
    <Box>
      <Box my={3}>
        <Box><Typography component="span"><b>Partner Name:</b></Typography><Typography component="span"> {company.name}</Typography></Box>
        <Box><Typography component="span"><b>Address:</b></Typography><Typography component="span"> {address}</Typography></Box>
        <Box><Typography component="span"><b>SharePoint Directory:</b></Typography><Typography component="span"> {company.rootDirPath.slice(1)}</Typography></Box>
        <Box><Typography component="span"><b>Email Addresses Domain Names:</b></Typography><Typography component="span"> {company.domains || "N/A"}</Typography></Box>
      </Box>
      <Box>
        <Button onClick={() => history.push(`/company/edit/${company.id}/`)}>Edit</Button>
      </Box>
    </Box>
  );
};

CompanyDetails.propTypes = {
  company: PropTypes.object,
};

CompanyDetails.defaultProps = {

};

