import { apiService } from "./services";

/**  @type ServerToLocalMap */
const SERVER_TO_LOCAL_MAP = {
// server key         local key              type
  "id":               'id',               // number
  "name":             "name",             // string
  "address":          "address",          // string | null
  "address2":         'address2',         // string | null
  "postal_code":      "postalCode",       // string | null
  "city":             "city",             // string | null
  "state":            "state",            // string | null
  "country":          "country",          // string | null
  "root_dir_path":    "rootDirPath",      // string | null
  "domains":          "domains",          // string | null
};

/**  @type LocalToServerMap */
const LOCAL_TO_SERVER_MAP = apiService.createLocalToServerItemMap(SERVER_TO_LOCAL_MAP);

/**  @type function(ServerItemData): LocalItemData */
const serverToLocal = apiService.createServerToLocalTransformer(SERVER_TO_LOCAL_MAP);

/**  @type function(LocalItemData): ServerItemData */
const localToServer = apiService.createLocalToServerTransformer(LOCAL_TO_SERVER_MAP);

/**
 * Creates company
 * @param {Object.<string, *>} fields
 * @return {Promise<any>}
 */
export function create(fields = {}) {
  return apiService.post('/company/', {
    ...localToServer(fields),
  }, {}, true, {
    transformResponse: (raw) => serverToLocal(JSON.parse(raw)),
  });
}

/**
 * Updates company by ID
 * @param {ServerID} companyID
 * @param {Object.<string, *>} fields
 * @return {Promise<any>}
 */
export function update(companyID, fields = {}) {
  return apiService.patch(`/company/${companyID}/`, {
    ...localToServer(fields),
  }, {}, true, {
    transformResponse: (raw) => serverToLocal(JSON.parse(raw)),
  });
}

/**
 * Removes company by ID
 * @param {ServerID} companyID
 * @return {Promise<any>}
 */
export function remove(companyID) {
  return apiService.del(`/company/${companyID}/`, {}, {}, true);
}

/**
 * Gets company by ID
 * @param {ServerID} companyID
 * @return {Promise<any>}
 */
export function get(companyID) {
  return apiService.get(`/company/${companyID}/`, {}, true, {
    transformResponse: (raw) => serverToLocal(JSON.parse(raw)),
  });
}

/**
 * Gets list of companies
 * @return {Promise<any>}
 */
export function getList() {
  return apiService.get(`/company/`, {}, true, {
    transformResponse: (raw) => JSON.parse(raw).map(el => serverToLocal(el)),
  });
}
