import { useRef, useEffect } from "react";

const events = [
  'mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'
];

export function useIdleTimer(timoutInSeconds = 60 * 60, callback = () => {}) {
  const timer = useRef(null);

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(callback, timoutInSeconds * 1000);
    };

    events.forEach(function(eventName) {
      document.addEventListener(eventName, resetTimer, true);
    });

    resetTimer();

    return () => {
      events.forEach(function(eventName) {
        document.removeEventListener(eventName, resetTimer, true);
      });
    };
  }, [timoutInSeconds, callback]);
}
