import LoginLayout from "../../../layouts/LoginLayout";
import React, { useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import Button from "../../../lib/Button";
import { RouteLink } from "../../../lib/Link";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useLocation } from 'react-router';
import { confirmByCode } from "../../../../api/AuthAPI";
import { Redirect } from "react-router";
import { loginByReadyToken } from "../../../../store/actions/AuthActions";

export default function Restore() {
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const auth = useSelector((state) => state.auth);
  const { handleSubmit, register, errors } = useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const login = (token) => {
    dispatch(loginByReadyToken(token));
  };

  const submitSuccessComponent = !submitSuccess ? (
    <Typography color="error">{errorMessage}</Typography>
  ) : null;

  const onSubmit = ({ code }) => {
    confirmByCode(code, location.state.email)
      .then((res) => {
        let data = res.data;
        let { token } = data;
        login(token);
      })
      .catch((res) => {
        if (res.response.status === 401) {
          setErrorMessage('Code you entered is wrong. Go to the Login Page and try again.');
        } else if (res.response.status === 400) {
          setErrorMessage('Code is expired. Go to the Login Page and try again.');
        } else {
          setErrorMessage('Something went wrong. Go to the Login Page and try again.');
        }
        setSubmitSuccess(false);
      })
    // requestPasswordReset(
    //   data.email,
    //   window.location.protocol + '//' + window.location.host + '/password-reset-complete/'
    // )
    //   .then(() => setSubmitSuccess(true))
    //   .catch(() => setSubmitSuccess(false));
  };

  return !location.state.email || auth.authData ? (
    <Redirect to="/" />
  ) : (
    <LoginLayout
      heading="Partner Portal"
      subhead=""
      desc="The confirmation code has been sent to your email. Please, put it to the field below."
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <TextField
            error={Boolean(errors.email)}
            fullWidth
            id="code"
            label="Code"
            type="text"
            name="code"
            defaultValue=""
            helperText={(errors.code && "This field is required") || ""}
            variant="outlined"
            inputRef={register({ required: true })}
          />
        </Box>

        <Box my="30px">
          <Button type="submit">Check</Button>
        </Box>

        <Box component="a" my="30px" display="block">
          <RouteLink to="/login/">Back to the login</RouteLink>
        </Box>
      </form>

      {submitSuccessComponent}
    </LoginLayout>
  );
}
