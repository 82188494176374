/**
 * @module @libs/Ducks/extensions/StateExtension
 */

import { produce } from "immer";
import { DuckExtension } from "./DuckExtension";

export function createExtension(namespace = '') {
  /***************************************************************************
   * Action types
   ***************************************************************************/
  const TOGGLE = `${namespace}/TOGGLE`;
  const BULK_TOGGLE = `${namespace}/BULK_TOGGLE`;

  /***************************************************************************
   * Actions
   ***************************************************************************/
  /**
   * Bulk toggle
   * @param {Object.<string, *>} bulk
   * @return {{type: string, bulk: Object.<string, *>}}
   */
  function bulkToggle(bulk) {
    return {
      type: BULK_TOGGLE,
      bulk,
    };
  }

  /**
   * Toggle
   * @param {string} key
   * @param {*} value
   * @return {{type: string, value: *, key: string}}
   */
  function toggle(key, value) {
    return {
      type: TOGGLE,
      key,
      value,
    };
  }

  /***************************************************************************
   * Selectors
   ***************************************************************************/
  /**
   * @param {Object} state
   * @param {string} key
   * @return {*}
   */
  function selectField(state, key = '') {
    return state[namespace][key];
  }

  /***************************************************************************
   * Extension class
   ***************************************************************************/
  class StateExtension extends DuckExtension {
    actionTypes = {
      TOGGLE,
      BULK_TOGGLE,
    };

    actions = {
      toggle,
      bulkToggle,
    };

    selectors = {
      selectField,
    };

    /**
     * Reducer
     * @param {Object} state
     * @param {string} type
     * @param {string} key
     * @param {*} value
     * @param {Object.<string, *>} bulk
     * @return {Object}
     */
    reducer(state, { type, key, value, bulk }) {
      switch (type) {
        case TOGGLE:
          return produce(state, nextState => {
            if (nextState[key] === undefined) return nextState;
            nextState[key] = value;
          });

        case BULK_TOGGLE:
          return produce(state, nextState => {
            Object.keys(bulk).forEach(key => {
              if (nextState[key] === undefined) return;
              nextState[key] = bulk[key];
            });
          });

        default:
          return state;
      }
    }
  }

  return new StateExtension();
}
