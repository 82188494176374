import GroupIcon from '@material-ui/icons/Group';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export const MENU_CONF = {
  items: {
    admin: [
      { to: "/", label: "Partners", icon: BusinessIcon },
      { to: "/users/", label: "Users", icon: GroupIcon },
      { to: "/profile/", label: "My account", icon: AccountCircleIcon },
    ],
    manager: [
      { to: "/", label: "Portal", icon: FolderSharedIcon },
      { to: "/users/", label: "Users", icon: GroupIcon },
      { to: "/profile/", label: "My account", icon: AccountCircleIcon },
    ],
    user: [
      { to: "/", label: "Portal", icon: FolderSharedIcon },
      { to: "/profile/", label: "My account", icon: AccountCircleIcon },
    ],
    anonymous: [
      { to: "/login", label: "Sign In", icon: ExitToAppIcon },
    ],
  }
};
