import { apiService } from "./services";

/** @type ServerToLocalMap */
const FILE_SERVER_TO_LOCAL_MAP = {
// server key         local key                type
  "type":             "type",               // string: "file"
  "name":             "name",               // string
  "download_link":    "downloadLink",       // string
  "view_name":        "viewName",           // string
  "view_url":         "viewURL",            // string
  "url":              "url",                // string
  "uid":              "id",                 // string
  "modified":         "dateModified"        // string
};

/**  @type function(ServerItemData): LocalItemData */
const fileServerToLocal = apiService.createServerToLocalTransformer(FILE_SERVER_TO_LOCAL_MAP);

/** @type ServerToLocalMap */
const FOLDER_SERVER_TO_LOCAL_MAP = {
// server key         local key                type
  "type":             "type",               // string: "folder"
  "name":             "name",               // string
  "view_name":        "viewName",           // string
  "view_url":         "viewURL",            // string
  "url":              "url",                // string
  "uid":              "id",                 // string
  "modified":         "dateModified"        // string
};

/**  @type function(ServerItemData): LocalItemData */
const folderServerToLocal = apiService.createServerToLocalTransformer(FOLDER_SERVER_TO_LOCAL_MAP);

/**
 * @param {string} raw
 * @return {*[]}
 */
const transformResponse = (raw) => {
  let data = [];
  try {
    data = JSON.parse(raw);
  } catch (e) {
    return data;
  }
  return [
    ...data.folders.payloads.map(el => folderServerToLocal(el)),
    ...data.files.payloads.map(el => fileServerToLocal(el)),
  ].sort((a, b) => {
    return a.viewName.localeCompare(b.viewName);
  });
};

/**
 * Get structure by path. Use it only for roles "user" and "manager"
 * @param {string} path
 * @return {Promise<any>}
 */
export function getStructure(path = '/') {
  return apiService.post('/cloud/', {
    path,
  }, {}, true,{ transformResponse });
}

/**
 * Search by files' and folders' names. Use it only for roles "user" and "manager"
 * @param {string} search
 * @return {Promise<any>}
 */
export function search(search = '') {
  return apiService.post('/cloud/', {
    search,
  }, {}, true, { transformResponse });
}

/**
 * Get structure for company by path. Use it only for roles "admin"
 * @param {string} path
 * @param {ServerID} companyID
 * @return {Promise<any>}
 */
export function getStructureAsCompany(path, companyID) {
  return apiService.post('/cloud/', {
    path,
    asCompany: companyID,
  }, {}, true, { transformResponse });
}

/**
 * Search by files' and folders' names for defined company. Use it only for roles "admin"
 * @param {string} search
 * @param {ServerID} companyID
 * @return {Promise<any>}
 */
export function searchAsCompany(search = '', companyID) {
  return apiService.post('/cloud/', {
    search,
    asCompany: companyID,
  }, {}, true, { transformResponse });
}
