/**
 * @module store/ducks/files.duck
 */

import { put, takeEvery, select, all, call } from 'redux-saga/effects';
import { produce } from "immer";

import { createExtension as createStateExtension } from '../../libs/Ducks/extensions/StateExtension';
import { createExtension as createReqExtension } from "../../libs/Ducks/extensions/RequestsExtension";
import { Record } from "../../libs/Ducks";
import * as FilesAPI from '../../api/FilesAPI';

/**
 * @description Duck name
 * @type {string}
 */
const DUCK = 'files';

/**
 * @description Duck namespace
 * @type {string}
 */
export const NAMESPACE = `${DUCK}`;

/***************************************************************************
 * Duck extensions
 ***************************************************************************/
const stateExtension = createStateExtension(NAMESPACE);
const reqExt = createReqExtension(NAMESPACE);

/***************************************************************************
 * Request keys
 ***************************************************************************/
export const REQ_KEY_ROOT = 'rootReq';
export const REQ_KEY_SEARCH = 'searchReq';
export const REQ_KEY_SUB_ITEMS = 'subItemsReq';

/***************************************************************************
 * Records
 ***************************************************************************/
/**
 * Item record
 * @type {{new(*=): RecordItem, prototype: RecordItem}}
 */
const ItemRecord = Record({
  type: null,
  name: null,
  downloadLink: null,
  viewName: null,
  viewURL: null,
  url: null,
  id: null,
  dateModified: null,
  children: [],
  parent: null,
  childLoaded: false,
});

/**
 * Reducer record
 * @type {{new(*=): RecordItem, prototype: RecordItem}}
 */
const ReducerRecord = Record({
  [REQ_KEY_ROOT]: new reqExt.records.RequestRecord(),
  [REQ_KEY_SEARCH]: new reqExt.records.RequestRecord(),
  [REQ_KEY_SUB_ITEMS]: new reqExt.records.RequestRecord(),
  items: [],
  searchItems: [],
  errors: [],
});

/***************************************************************************
 * Actions
 ***************************************************************************/
export const { TOGGLE, DEEP_TOGGLE, BULK_TOGGLE } = stateExtension.actionTypes;
export const SET_ITEMS = `${NAMESPACE}/SET_ITEMS`;
export const SET_SUB_ITEMS = `${NAMESPACE}/SET_SUB_ITEMS`;
export const SET_SEARCH_ITEMS = `${NAMESPACE}/SET_SEARCH_ITEMS`;
export const SET_SEARCH_SUB_ITEMS = `${NAMESPACE}/SET_SEARCH_SUB_ITEMS`;

/***************************************************************************
 * Reducer
 ***************************************************************************/
/**
 * Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function reducer(state = new ReducerRecord(), action) {
  let {
    type,
    items,
  } = action;

  switch (type) {
    case SET_ITEMS:
      return produce(state, s => {
        // delete old items
        let toDelete = [];
        s.items.forEach((el) => {
          if (el.parent !== null) return;
          let newIndex = items.findIndex(fl => fl.id === el.id);
          if (newIndex === -1) {
            toDelete.push(el.id);
          }
        });
        toDelete.forEach(id => {
          s.items.splice(s.items.findIndex(el => el.id === id), 1);
        });

        // update or insert new items
        items.forEach((el) => {
          let oldIndex = s.items.findIndex(fl => {
            if (fl === undefined) return false;
            return fl.id === el.id;
          });
          oldIndex > -1 ?
            s.items[oldIndex] = { ...s.items[oldIndex], ...el,} :
            s.items.push(el);
        });
      });

    case SET_SUB_ITEMS:
      return produce(state, s => {
        let storageKey = 'items';

        if (!items.length) return;
        let parentID = items[0].parent,
          parent = s[storageKey].find(el => el.id === parentID);
        if (!parent) return;

        // delete old items
        let toDelete = [];
        s[storageKey].forEach((el) => {
          if (el.parent !== parentID) return;
          let newIndex = items.findIndex(fl => fl.id === el.id);
          if (newIndex === -1) {
            toDelete.push(el.id);
          }
        });
        toDelete.forEach(id => {
          s[storageKey].splice(s[storageKey].findIndex(el => el.id === id), 1);
        });

        // update or insert new items
        items.forEach((el) => {
          let oldIndex = s[storageKey].findIndex(fl => fl.id === el.id);
          oldIndex > -1 ?
            s[storageKey][oldIndex] = { ...s[storageKey][oldIndex], ...el,} :
            s[storageKey].push(el);
        });

        // update parent's "children" field
        let parentIndex = s[storageKey].findIndex(el => el.id === parentID);
        s[storageKey][parentIndex].children = s[storageKey].filter(el => el.parent === parentID).map(el => el.id);
        s[storageKey][parentIndex].childLoaded = true;
      });

    case SET_SEARCH_ITEMS:
      return produce(state, s => {
        s.searchItems = items;
      });

    case SET_SEARCH_SUB_ITEMS:
      return produce(state, s => {
        let storageKey = 'searchItems';

        if (!items.length) return;
        let parentID = items[0].parent,
          parent = s[storageKey].find(el => el.id === parentID);
        if (!parent) return;

        // delete old items
        let toDelete = [];
        s[storageKey].forEach((el) => {
          if (el.parent !== parentID) return;
          let newIndex = items.findIndex(fl => fl.id === el.id);
          if (newIndex === -1) {
            toDelete.push(el.id);
          }
        });
        toDelete.forEach(id => {
          s[storageKey].splice(s[storageKey].findIndex(el => el.id === id), 1);
        });

        // update or insert new items
        items.forEach((el) => {
          let oldIndex = s[storageKey].findIndex(fl => fl.id === el.id);
          oldIndex > -1 ?
            s[storageKey][oldIndex] = { ...s[storageKey][oldIndex], ...el,} :
            s[storageKey].push(el);
        });

        // update parent's "children" field
        let parentIndex = s[storageKey].findIndex(el => el.id === parentID);
        s[storageKey][parentIndex].children = s[storageKey].filter(el => el.parent === parentID).map(el => el.id);
        s[storageKey][parentIndex].childLoaded = true;
      });

    default: {
      let next = stateExtension.reducer(state, action);
      next = reqExt.reducer(state, action);
      return next;
    }
  }
}

export default reducer;

/***************************************************************************
 * Action creators
 ***************************************************************************/
export const { toggle, bulkToggle, deepToggle } = stateExtension.actions;
export const { emitReq } = reqExt.actions;

/**
 * @param {string} path
 * @param {?number} company
 * @return {{reqData: Object, type: string, key: RequestKey}}
 */
export function loadItems(path, company = null) {
  return emitReq(REQ_KEY_ROOT, {
    path,
    company,
  });
}

/**
 * @param {string} search
 * @param {?number} company
 * @return {{reqData: Object, type: string, key: RequestKey}}
 */
export function searchItems(search, company = null) {
  return emitReq(REQ_KEY_SEARCH, {
    search,
    company,
  });
}

/**
 * @param {string} path
 * @param {string} childOf
 * @param {boolean} isStoreToSearch
 * @param {?number} company
 * @return {{reqData: Object, type: string, key: RequestKey}}
 */
export function loadSubItems(path, childOf, isStoreToSearch = false, company = null) {
  return emitReq(REQ_KEY_SUB_ITEMS, {
    path,
    childOf,
    isStoreToSearch,
    company,
  });
}

/***************************************************************************
 * Sagas
 ***************************************************************************/
/**
 * Load root items handler
 */
reqExt.addHandler(REQ_KEY_ROOT, function* ({
  path,
  company = null,
}) {
  let handler = company !== null ? FilesAPI.getStructureAsCompany : FilesAPI.getStructure,
    res = yield handler(path, company);

  res.data = res.data.map(el => new ItemRecord({
    ...el,
    parent: null,
  }));

  yield put({
    type: SET_ITEMS,
    items: res.data,
  });
});

/**
 * Load search items handler
 */
reqExt.addHandler(REQ_KEY_SEARCH, function* ({
  search,
  company = null,
}) {
  let res = {};
  try {
    let handler = company !== null ? FilesAPI.searchAsCompany : FilesAPI.search;
    res = yield handler(search, company);
  } catch (e) {
    if (e.response.status === 404) {
      res.data = [];
    }
  }

  res.data = res.data.map(el => new ItemRecord({
    ...el,
    parent: null,
  }));

  yield put({
    type: SET_SEARCH_ITEMS,
    items: res.data,
  });
});

/**
 * Load subitems handler
 */
reqExt.addHandler(REQ_KEY_SUB_ITEMS, function* ({
  path,
  childOf,
  company = null,
  isStoreToSearch = false,
}) {
  let handler = company !== null ? FilesAPI.getStructureAsCompany : FilesAPI.getStructure,
    res = yield handler(path, company);

  res.data = res.data.map(el => new ItemRecord({
    ...el,
    parent: childOf,
  }));

  yield put({
    type: isStoreToSearch ? SET_SEARCH_SUB_ITEMS : SET_SUB_ITEMS,
    items: res.data,
  });
});

/**
 * Main saga
 * @method
 */
export const saga = function* () {
  yield all([
    ...reqExt.sagas,
  ]);
};

/***************************************************************************
 * Selectors
 ***************************************************************************/
export const {
  selectIsRunning,
  selectIsSuccess,
  selectIsError,
  selectErrors,
  selectRequest,
  selectResponse,
} = reqExt.selectors;

/**
 * @param {Object} state
 * @method
 * @return {RecordItem}
 */
export const selectAll = (state) => state[NAMESPACE];

/**
 * @param {Object} state
 * @param {string} key
 * @method
 * @return {*}
 */
export const selectField = (state, key) => state[NAMESPACE][key];

/**
 * @param {Object} s
 * @return {boolean}
 */
export const selectIsLoading = (s) =>
  selectIsRunning(s, REQ_KEY_ROOT) || selectIsRunning(s, REQ_KEY_SEARCH) || selectIsRunning(s, REQ_KEY_SUB_ITEMS);

/**
 * @param {Object} s
 * @return {*[]}
 */
export const selectItems = (s) => selectField(s, 'items');

/**
 * @param {Object} s
 * @return {*[]}
 */
export const selectSearchItems = (s) => selectField(s, 'searchItems');
