import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
  create,
  get as getCompany,
  update,
} from "../../../../api/CompaniesAPI";
import { COOLIT_COMPANY_NAME, COUNTRIES } from "../../../../config";
import { setSuccessMessage } from "../../../../store/actions/SnackbarActions";
import Button, { useButtonStyles } from "../../../lib/Button";
import DataLoader from "../../../shared/DataLoader";
import PageHeader from "../../../shared/PageHeader";
import PopupConfirm from "../../../shared/PopupConfirm";
import { removeCompanyAsync } from "../../../../store/actions/CompaniesActions";

const initialCompanyData = {
  name: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  rootDirPath: "",
  country: "",
};

const createCompany = (onSuccess) => (data) => {
  create(data).then(() => onSuccess());
};

const updateCompany = (onSuccess) => ({ companyId, ...data }) => {
  update(companyId, data).then(() => onSuccess());
};

export default function CompanyEdit() {
  const [companyData, setCompanyData] = useState(null);
  const history = useHistory();
  const { params } = useRouteMatch();

  useEffect(() => {
    let isFetching = true;

    if (params.id === "new-company" || !params.id) {
      setCompanyData({ data: initialCompanyData, isNewCompany: true });
    } else {
      getCompany(params.id)
        .then(
          ({ data }) =>
            isFetching && setCompanyData({ data, isNewCompany: false })
        )
        .catch(() => isFetching && history.push("/"));
    }

    return () => {
      isFetching = false;
    };
  }, []);

  return (
    <Fragment>
      <PageHeader
        left={`${params.id === "new-company" ? "Add New" : "Edit"} Partner`}
        right={COOLIT_COMPANY_NAME}
      />
      {companyData && companyData.data ? (
        <Container>
          <EditCompanyForm
            companyData={companyData.data}
            isNewCompany={companyData.isNewCompany}
          />
        </Container>
      ) : (
        <DataLoader />
      )}
    </Fragment>
  );
}

const InputBox = withStyles({
  root: {
    margin: "10px 0",
  },
})(Box);

function EditCompanyForm({ companyData, isNewCompany }) {
  const [open, toggle] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onBlur",
  });
  const dispatch = useDispatch();
  const btnClasses = useButtonStyles();

  const onSuccess = (message) => () => {
    history.push("/");
    dispatch(setSuccessMessage(message));
  };

  const onSubmit = isNewCompany
    ? createCompany(onSuccess("Partner was created successfully"))
    : updateCompany(onSuccess("Partner was updated successfully"));

  const validateDomainNames = (value) => {
    let items = value.split(",");
    for (let i = 0; i < items.length; i++) {
      let val = items[i].trim();
      if (!/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(val)) {
        return `Domain "${val}" is invalid.`;
      }
    }
    return true;
  };

  const deleteCompany = () => {
    history.push("/");
    dispatch(removeCompanyAsync(companyData.id));
  };

  return (
    <Box mt={5} maxWidth="600px" margin="0 auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.name)}
            id="name"
            name="name"
            label="Partner Name"
            defaultValue={companyData.name}
            inputRef={register({ required: true })}
            helperText={(errors.name && "This field is required") || ""}
          />
        </InputBox>

        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.domains)}
            id="domains"
            name="domains"
            label="Email Addresses Domain Names"
            defaultValue={companyData.domains}
            inputRef={register({ required: true, validate: { validateDomainNames } })}
            helperText={errors.domains ? (errors.domains.message || "This field is required") : ""}
          />
        </InputBox>

        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.address)}
            id="address"
            name="address"
            label="Address"
            defaultValue={companyData.address}
            inputRef={register({ required: true })}
            helperText={(errors.address && "This field is required") || ""}
          />
        </InputBox>
        <InputBox>
          <TextField
            fullWidth
            id="address2"
            name="address2"
            defaultValue={companyData.address2}
            label="Address 2 (optional)"
            inputRef={register()}
          />
        </InputBox>
        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.city)}
            id="city"
            name="city"
            label="City"
            defaultValue={companyData.city}
            inputRef={register({ required: true })}
            helperText={(errors.city && "This field is required") || ""}
          />
        </InputBox>
        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.state)}
            id="state"
            name="state"
            label="State/Region"
            defaultValue={companyData.state}
            inputRef={register({ required: true })}
            helperText={(errors.state && "This field is required") || ""}
          />
        </InputBox>

        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.postalCode)}
            id="postalCode"
            name="postalCode"
            label="Postal Code"
            defaultValue={companyData.postalCode}
            inputRef={register({ required: true })}
            helperText={(errors.postalCode && "This field is required") || ""}
          />
        </InputBox>

        <InputBox>
          <TextField
            fullWidth
            error={Boolean(errors.rootDirPath)}
            id="rootDirPath"
            name="rootDirPath"
            label="Share Point Folder"
            defaultValue={companyData.rootDirPath}
            inputRef={register({ required: true })}
            helperText={(errors.rootDirPath && "This field is required") || ""}
          />
        </InputBox>
        <FormControl fullWidth>
          <InputLabel id="country">Country</InputLabel>
          <Controller
            name="country"
            rules={{ required: true }}
            defaultValue={companyData.country}
            as={
              <Select
                fullWidth
                error={Boolean(errors.country)}
                aria-label="Country"
                labelId="country"
                id="country"
                name="country"
                label="Country"
              >
                {COUNTRIES.map((v) => (
                  <MenuItem v={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
          />
        </FormControl>
        {!isNewCompany ? (
          <input
            type="hidden"
            value={companyData.id}
            name="companyId"
            ref={register()}
          />
        ) : null}
        <Box mt="30px">
          <Grid container spacing={3}>
            {!isNewCompany ? (
              <Grid item xs={12} md="auto">
                <Button
                  onClick={() => toggle(true)}
                  className={btnClasses.root}
                >
                  Delete Partner
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={6} md="auto">
              <Button
                onClick={() => history.push("/")}
                className={btnClasses.root}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} md="auto">
              <Button type="submit" className={btnClasses.root}>
                {isNewCompany ? 'Create' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
      <PopupConfirm
        title="Delete Confirmation"
        content="You have selected to delete this partner."
        open={open}
        onAgreement={deleteCompany}
        onCancel={() => toggle(false)} />
    </Box>
  );
}
