/**
 * @module @components/pages/admin/CompanyView/CompanyView
 * @description The CompanyView component.
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import {
  Box,
  Container,
  Typography,
} from "@material-ui/core";

import PageHeader from "../../../shared/PageHeader";
import { Files } from "../../../shared/Files";
import DataLoader from "../../../shared/DataLoader";
import { get as getCompany } from "../../../../api/CompaniesAPI";
import { Breadcrumbs } from "../../../lib/Breadcrumbs";
import { CompanyDetails } from "./CompanyDetails";
import { COOLIT_COMPANY_NAME } from "../../../../config";
import { UsersList } from "../../../shared/Users/UsersList";

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const CompanyView = ({ match }) => {
  const history = useHistory(),
    [isLoading, setIsLoading] = useState(true),
    [company, setCompany] = useState(null);

  let id = parseInt(match.params.id);
  if (isNaN(id)) {
    history.push("/");
  }

  useEffect(() => {
    getCompany(id)
      .then((res) => {
        setCompany(res.data);
        setIsLoading(false);
      })
      .catch((e) => {
        history.push("/");
      });
  }, [id]);

  return isLoading ? (
    <DataLoader />
  ) : (
    <Box>
      <PageHeader right={company.name} left="View details" />
      <Container>
        <Box my={2}>
          <Box mb={3}>
            <Breadcrumbs
              items={[
                { id: "accounts", to: "/", label: "Partners" },
                { id, label: company.name },
              ]}
              current={id}
            />
          </Box>
          <Box mb={5}>
            <Typography variant="h4" gutterBottom>
              Portal
            </Typography>
            <Files company={id} />
          </Box>
          <Box mb={5}>
            <Typography variant="h4" gutterBottom>
              Users
            </Typography>
            <UsersList company={id} />
          </Box>
          <Box>
            <Typography variant="h4" gutterBottom>
              Partner Details
            </Typography>
            <CompanyDetails company={company} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

CompanyView.propTypes = {};

CompanyView.defaultProps = {};
