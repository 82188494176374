import { Box, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Button from "../../../lib/Button";
import LoginLayout from "../../../layouts/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { RouteLink } from "../../../lib/Link";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router";
import { login } from "../../../../api/AuthAPI";

export default function Login() {
  const { handleSubmit, register, errors, reset } = useForm();
  const auth = useSelector((state) => state.auth);
  const [responseData, setResponseData] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const history = useHistory();

  const onSubmit = (values) => {
    login(values.email, values.password).then(res => {
      history.push('/login/confirm/', {
        email: values.email,
      });
      setResponseData(res);
      setResponseError(null);
      reset();
    }).catch((e) => {
      setResponseError(e);
      setResponseData(null);
    });
  };

  return auth.authData ? (
    <Redirect to="/" />
  ) : (
    <LoginLayout
      heading="Partner Portal"
      subhead=""
      desc="Please enter your Partner Portal login credentials"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <TextField
            error={Boolean(errors.email)}
            fullWidth
            id="email"
            label="Email"
            type="email"
            name="email"
            defaultValue=""
            helperText={(errors.email && "This field is required") || ""}
            variant="outlined"
            inputRef={register({ required: true })}
          />
        </Box>
        <Box my="10px">
          <TextField
            error={Boolean(errors.password)}
            fullWidth
            id="password"
            name="password"
            type="password"
            label="Password"
            defaultValue=""
            helperText={(errors.password && "This field is required") || ""}
            variant="outlined"
            inputRef={register({ required: true })}
          />
        </Box>

        {responseError ? (
          <Typography color="error">Incorrect values.</Typography>
        ) : null}

        {responseData ? (
          <Typography>Code for login has been sent to your email.</Typography>
        ) : null}

        <Box my="30px" display="block">
          <RouteLink to="/password-reset/">Reset your password</RouteLink>
        </Box>

        <Button type="submit">Authenticate Me</Button>
      </form>
    </LoginLayout>
  );
}
