import { Switch, Route, Redirect } from "react-router-dom";
import { Accounts } from "../../pages/admin/Accounts";
import { Users } from "../../pages/admin/Users";
import EditUserPage from "../../pages/admin/EditUserPage";
import { Login, Restore, NewPassword } from "../../pages/anonymous/Login";
import CompanyEdit from "../../pages/admin/CompanyEdit/CompanyEdit";
import { CompanyView } from "../../pages/admin/CompanyView";
import Profile from "../../pages/common/Profile";

export default function AdminRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Accounts} />
      <Route exact path="/users/" component={Users} />
      <Route exact path="/users/edit/:id/" component={EditUserPage} />
      <Route exact path="/company/edit/:id?/" component={CompanyEdit} />
      <Route exact path="/company/:id/" component={CompanyView} />
      <Route exact path="/profile/" component={Profile} />
      <Route exact path="/login/" component={Login} />
      <Route exact path="/password-reset/" component={Restore} />
      <Route exact path="/password-reset-confirm/" component={NewPassword} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
