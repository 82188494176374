import LoginLayout from "../../../layouts/LoginLayout";
import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import Button from "../../../lib/Button";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation, useHistory } from "react-router";
import { makePasswordReset } from "../../../../api/AuthAPI";
import qs from "query-string";
import { Link } from "react-router-dom";
import { setSuccessMessage } from "../../../../store/actions/SnackbarActions";

export default function NewPassword() {
  const location = useLocation();
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitResultMessage, setSubmitResultMessage] = useState(null);
  const { handleSubmit, register, errors, watch } = useForm();
  const auth = useSelector((state) => state.auth.authData);
  const qsParse = qs.parse(location.search);
  const dispatch = useDispatch();
  const history = useHistory();

  let { newPassword: newPassVal } = watch();

  const onSubmit = (uidb64, token) => (data) => {
    makePasswordReset(data.newPassword, token, uidb64)
      .then(() => {
        dispatch(setSuccessMessage("Password changed successfully"));
        history.push('/');
        setSubmitSuccess(true);
      })
      .catch(() => {
        let cause = 'something went wrong';
        setSubmitResultMessage(`Can't change the password - ${cause}.`);
        setSubmitSuccess(false);
      });
  };

  return auth ? (
    <Redirect to="/" />
  ) : (
    <LoginLayout
      heading="Partner Portal"
      subhead=""
      desc="Please set up your new password."
    >
      <form onSubmit={handleSubmit(onSubmit(qsParse.uidb64, qsParse.token))}>
        <Box>
          <TextField
            error={!!errors.newPassword}
            fullWidth
            id="newPassword"
            label="New Password"
            type="password"
            name="newPassword"
            defaultValue=""
            helperText={errors.newPassword?.message}
            variant="outlined"
            inputRef={register({ required: "This field is required" })}
          />
        </Box>
        <Box mt="10px">
          <TextField
            error={!!errors.repeatPassword}
            fullWidth
            id="repeatPassword"
            label="Repeat Password"
            type="password"
            name="repeatPassword"
            defaultValue=""
            helperText={errors.repeatPassword?.message}
            variant="outlined"
            inputRef={register({
              required: "This field is required",
              validate: (value) => value !== newPassVal ? `Passwords don't match.` : true,
            })}
          />
        </Box>
        {submitSuccess !== null && (
          <Box my={3}>
            <Typography color={submitSuccess ? 'success' : 'error'}>
              {submitResultMessage}
            </Typography>
          </Box>
        )}
        <Box my={3}>
          {submitSuccess ? (
            <Button component={Link} to="/login">Login</Button>
          ) : (
            <Button type="submit">Set password</Button>
          )}
        </Box>
      </form>
    </LoginLayout>
  );
}
