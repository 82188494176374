import produce from "immer";
import {
  COMPANIES_REQUESTED,
  COMPANIES_LOAD_SUCCESS,
  COMPANY_LOAD_SUCCESS,
  COMPANY_REMOVED,
} from "../actions/CompaniesActions";

const initialState = {
  isCompanyLoading: false,
  isCompaniesLoading: false,
  companyData: null,
  companiesData: null,
};

export default function CompaniesReducer(state = initialState, action) {
  switch (action.type) {
    case COMPANY_LOAD_SUCCESS:
      return produce(state, (draft) => {
        draft.isCompanyLoading = false;
        draft.companyData = action.payload;
      });

    case COMPANIES_REQUESTED:
      return produce(state, (draft) => {
        draft.companyData = null;
        draft.companiesData = null;
        draft.isCompanyLoading = true;
        draft.isCompaniesLoading = true;
      });

    case COMPANIES_LOAD_SUCCESS:
      return produce(state, (draft) => {
        draft.isCompaniesLoading = false;
        draft.companiesData = action.payload;
      });

    case COMPANY_REMOVED:
      return produce(state, (draft) => {
        const id = action.payload;
        draft.companiesData = (draft.companiesData || []).filter((company) => company.id !== id);
      });
    default:
      return state;
  }
}
