import React, { useState, Fragment } from "react";
import { Box, AppBar, makeStyles, Button, Typography } from "@material-ui/core";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { VERSION } from "../../../config";
import CompanyLogo from "../../../assets/Group.svg";
import { MENU_CONF } from "./menu";
import Toolbar from "@material-ui/core/Toolbar";
import { authService } from "../../../api/services";
import { authLogout } from "../../../store/actions/AuthActions";

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.headerBg,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
  },

  logo: {
    height: 50,
    [theme.breakpoints.down('sm')]: {
      height: 35,
    }
  },

  headerContent: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  navLink: {
    '&.active': {
      backgroundColor: `rgba(0, 0, 0, 0.04)`
    }
  }
}));

export default function Header() {
  const classes = useHeaderStyles(),
    theme = useTheme(),
    isMobile = useMediaQuery(theme.breakpoints.down('sm')),
    role = useSelector(({ auth }) => auth.authData?.role || "anonymous"),
    items = [...MENU_CONF.items[role]],
    dispatch = useDispatch(),
    [isOpen, setIsOpen] = useState(false);

  if (role !== "anonymous") items.push({
    onClick: () => {
      authService.removeToken();
      dispatch(authLogout());
    },
    label: "Logout",
    icon: ExitToAppIcon,
  });

  return (
    <Fragment>
      <AppBar className={classes.root} position="fixed">
        <Toolbar>
          <Box mr="auto">
            <a href="//coolitsystems.com" target="_blank">
              <img src={CompanyLogo} alt="CoolIT Systems" className={classes.logo} />
            </a>
          </Box>
          {/*<Box color="warning.main" mx="auto">*/}
          {/*  <Typography>*/}
          {/*    <b>{VERSION}</b>*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
          <Box ml="auto">
            {isMobile ? (
              <IconButton onClick={() => setIsOpen(!isOpen)} edge="end" aria-label="menu">
                <MenuIcon />
              </IconButton>
            ) : items.map((el, i) => (
              <Button
                className={classes.navLink}
                exact={true}
                component={el.onClick ? 'button' : NavLink} to={el.to} onClick={el.onClick} key={el.label}>{el.label}</Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchor="right"
      >
        <List>
          {items.map(el => (
            <ListItem
              className={classes.navLink}
              button
              exact={true}
              key={el.label}
              component={el.onClick ? 'button' : NavLink}
              to={el.to}
              onClick={() => {
                setIsOpen(false);
                el.onClick && el.onClick();
              }}>
              <ListItemIcon><el.icon /></ListItemIcon>
              <ListItemText>{el.label}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Fragment>
  );
}
