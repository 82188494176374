/**
 * @module APIService
 * @description Provides this features:
 * - wrapper over the axios
 * - multilang header
 * - auth features
 * - data format mappers
 */

import axios from 'axios';

/** @typedef {Object} AxiosReqConf */
/** @typedef {number} ServerID */
/** @typedef {Object.<string, string>} ServerToLocalMap */
/** @typedef {Object.<string, string>} LocalToServerMap */
/** @typedef {Object.<string, string|number|Array|Object|null>} ServerItemData */
/** @typedef {Object.<string, string|number|Array|Object|null>} LocalItemData */
/** @typedef {string} AuthToken */

/**
 * API Service class
 */
export class APIService {
  DEFAULT_HEADERS = {};

  /**
   * @param {boolean} isUseAuth
   * @param {string} baseURL
   * @param {boolean} isUseMultilang
   * @param {function} getLocale
   * @param {AuthService} authService
   */
  constructor({
    isUseAuth = false,
    baseURL = '',
    isUseMultilang = false,
    getLocale = () => '',
    authService = null,
  }) {
    this.isUseAuth = isUseAuth;
    this.baseURL = baseURL;
    this.isUseMultilang = isUseMultilang;
    this.getLocale = getLocale;
    this.authService = authService;
  }

  /**
   * Generate headers for the request
   * @param {boolean} isUseAuth - is use Authorization header
   * @return {Object}
   */
  getHeaders(isUseAuth = undefined) {
    let headers = {
      ...this.DEFAULT_HEADERS,
    };

    if (this.isUseMultilang) {
      headers['Accept-Language'] = this.getLocale();
    }

    if (isUseAuth !== undefined ? isUseAuth : this.isUseAuth) {
      let token = this.authService.token;
      if (token) {
        headers['Authorization'] = `Token ${token}`;
      }
    }

    return headers;
  }

  /**
   * Generate request config
   * @param {AxiosReqConf} conf
   * @return {AxiosReqConf}
   */
  getRequestConf(conf = {}) {
    return {
      baseURL: this.baseURL,
      ...conf,
      headers: {
        ...conf.headers,
        ...this.getHeaders(conf.isUseAuth),
      },
    };
  }

  /**
   * Request handler
   * @param {AxiosReqConf} conf
   * @return {Promise<any>}
   */
  request(conf = {}) {
    return new Promise((res, rej) => {
      axios(this.getRequestConf(conf))
        .then(res)
        .catch(e => {
          if (e.response && e.response.status === 401) {
            if (!conf.ignore401) {
              this.authService.onTokenExpired();
            }
          }
          rej(e);
        });
    });
  }

  /**
   * Make GET request
   * @param {string} url
   * @param {Object} query
   * @param {boolean|undefined} isUseAuth
   * @param {AxiosReqConf} conf
   * @return {Promise<any>}
   */
  get(url, query = {}, isUseAuth = undefined, conf = {}) {
    return this.request({
      method: 'get',
      url,
      params: query,
      isUseAuth,
      ...conf,
    });
  }

  /**
   * Make POST request
   * @param {string} url
   * @param {Object} data
   * @param {Object} query
   * @param {boolean} isUseAuth
   * @param {AxiosReqConf} conf
   * @return {Promise<any>}
   */
  post(url, data = {}, query = {}, isUseAuth = undefined, conf = {}) {
    return this.request({
      method: 'post',
      url,
      params: query,
      data,
      isUseAuth,
      ...conf,
    });
  }

  /**
   * Make PUT request
   * @param {string} url
   * @param {Object} data
   * @param {Object} query
   * @param {boolean} isUseAuth
   * @param {AxiosReqConf} conf
   * @return {Promise<any>}
   */
  put(url, data = {}, query = {}, isUseAuth = undefined, conf = {}) {
    return this.request({
      method: 'put',
      url,
      params: query,
      data,
      isUseAuth,
      ...conf,
    });
  }

  /**
   * Make PATCH request
   * @param {string} url
   * @param {Object} data
   * @param {Object} query
   * @param {boolean} isUseAuth
   * @param {AxiosReqConf} conf
   * @return {Promise<any>}
   */
  patch(url, data = {}, query = {}, isUseAuth = undefined, conf = {}) {
    return this.request({
      method: 'patch',
      url,
      params: query,
      data,
      isUseAuth,
      ...conf,
    });
  }

  /**
   * Make DELETE request
   * @param {string} url
   * @param {Object} data
   * @param {Object} query
   * @param {boolean} isUseAuth
   * @param {AxiosReqConf} conf
   * @return {Promise<any>}
   */
  del(url, data = {}, query = {}, isUseAuth = undefined, conf = {}) {
    return this.request({
      method: 'delete',
      url,
      params: query,
      data,
      isUseAuth,
      ...conf,
    });
  }

  /**
   * Creates local to server map object for items fields
   * @param {ServerToLocalMap} serverToLocalMap
   * @return {LocalToServerMap}
   */
  createLocalToServerItemMap(serverToLocalMap = {}) {
    let out = {};
    Object.keys(serverToLocalMap).forEach(key => out[serverToLocalMap[key]] = key);
    return out;
  }

  /**
   * Creates server to local transformer function
   * @param {ServerToLocalMap} serverToLocalMap
   * @return {function(ServerItemData): LocalItemData}
   */
  createServerToLocalTransformer(serverToLocalMap) {
    return (serverItem) => {
      let out = {};
      Object.keys(serverItem).forEach(key => out[serverToLocalMap[key] !== undefined ? serverToLocalMap[key] : key] = serverItem[key]);
      return out;
    };
  }

  /**
   * Creates local to server transformer function
   * @param {LocalToServerMap} localToServerMap
   * @return {function(LocalItemData): ServerItemData}
   */
  createLocalToServerTransformer(localToServerMap) {
    return (localItem) => {
      let out = {};
      Object.keys(localItem).forEach(key => {
        if (localToServerMap[key] !== undefined) {
          out[localToServerMap[key]] = localItem[key]
        }
      });
      return out;
    };
  }
}
