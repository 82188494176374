import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PageHeaderBg from "../../../assets/PageHeaderBg.jpg";
import Heading from "../../lib/Heading";

const usePageHeaderStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${PageHeaderBg}) no-repeat center center`,
    backgroundSize: "cover",
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  },

  right: {
    fontSize: 15,
    color: "#FFFFFF",
    [theme.breakpoints.down('sm')]: {
      '& h3': {
        textAlign: 'inherit',
      },
    },
  },

  left: {

  },

  divider: {
    height: "3rem",
    width: "1px",
    background: "white",
    margin: "0 50px",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default function PageHeader({ children, left, right }) {
  const classes = usePageHeaderStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      py="25px"
      mb={2}
    >
      {left && (
        <Box className={classes.right}>
          <Heading size={48} color="white">
            {left}
          </Heading>
        </Box>
      )}
      {left && right && (
        <Divider orientation="vertical" className={classes.divider} />
      )}

      {right && (
        <Typography className={classes.right}>{right}</Typography>
      )}
    </Box>
  );
}
