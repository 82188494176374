import { apiService, authService } from "./services";
import { ENV_IS_PRODUCTION } from "./services";

/**
 * Make login request
 * @param {string} email
 * @param {string} password
 */
export function login(email, password) {
  return apiService
    .post("/auth/signin/", {
      email: email.toLowerCase(),
      password,
      redirect_url: !ENV_IS_PRODUCTION ? "https://localhost:3000/" : undefined
    });
}

/**
 * Clear token and call "tokenExpired" callback
 */
export function logout() {
  authService.removeToken();
  authService.onTokenExpired();
  return null
}

/**
 * Request password reset. If email is valid, server will send a letter with link on this email
 * @param {string} email
 * @param {string} redirectURL - where is redirect user after following the link in letter
 * @return {Promise<any>}
 */
export function requestPasswordReset(email, redirectURL) {
  return apiService.post("/auth/password-reset/", {
    email: email.toLowerCase(),
    redirect_url: redirectURL,
  });
}

/**
 * Sets a new password
 * @param {string} password
 * @param {string} resetToken - get it from query param
 * @param {string} uidb64 - get it from query param
 */
export function makePasswordReset(password, resetToken, uidb64) {
  return apiService.patch("/auth/password-reset/complete/", {
    password,
    token: resetToken,
    uidb64,
  });
}

export function confirmByCode(code = '', email = '') {
  return apiService.post("/auth/signin/complete/", {
    code,
    email,
  }, {}, false, {
    ignore401: true,
  });
}
