import { makeStyles, Link as MuiLink } from "@material-ui/core";
import { Link } from "react-router-dom";

export const useLinkStyles = makeStyles({
  root: {
    margin: "0 4px",
    cursor: "pointer",
    textDecoration: "underline",
    color: "black",
    "&:visited": {
      color: "black",
    },
  },
});

export const ActionLink = ({ children, ...props }) => {
  const classes = useLinkStyles();
  return (
    <MuiLink {...props} classes={{ root: classes.root }}>
      {children}
    </MuiLink>
  );
};

export const RouteLink = ({ children, ...props }) => {
  const classes = useLinkStyles();
  return (
    <Link {...props} className={classes.root}>
      {children}
    </Link>
  );
};
