/**
 * @module components/pages/common/Documents/components/SearchBox
 * @description The SearchBox component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { Box, TextField, IconButton } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    maxWidth: '30rem'
  },
  input: {
    margin: 'auto 0',
  },
  icon: {
    // minWidth: 64,
  }
}));

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const SearchBox = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm(),
    classes = useStyles();

  const handleSubmitForm = ({ search }) => {
    onSubmit(search);
  };

  return (
    <Box>
      <form className={classes.form} onSubmit={handleSubmit(handleSubmitForm)}>
        <TextField
          className={classes.input}
          name="search"
          inputRef={register}
          label={null}
          fullWidth
          margin="normal"
          variant="outlined"
          size="small"
        />
        <Box>
          <IconButton
            edge="end"
            size="normal"
            className={classes.icon}
            type="submit">
            <Search />
          </IconButton>
        </Box>
      </form>
    </Box>
  );
};

SearchBox.propTypes = {

};

SearchBox.defaultProps = {

};

