import produce from "immer";
import {
  SNACKBAR_CLOSE,
  SNACKBAR_SET_MESSAGE,
  SNACKBAR_SET_SUCCESS_MESSAGE,
} from "../actions/SnackbarActions";

const initialState = {
  message: null,
  type: 'success',
  isOpen: false,
};

export default function SnackbarReducer(state = initialState, action) {
  switch (action.type) {
    case SNACKBAR_SET_MESSAGE:
      return produce(state, (draft) => {
        draft.message = action.payload;
        draft.isOpen = true;
        draft.type = action.messageType;
      });

    case SNACKBAR_SET_SUCCESS_MESSAGE:
      return produce(state, (draft) => {
        draft.message = action.payload;
        draft.isOpen = true;
        draft.type = 'success';
      });

    case SNACKBAR_CLOSE:
      return produce(state, (draft) => {
        draft.isOpen = false;
      });

    default:
      return state;
  }
}
