import React from "react";
import { get as getUser } from "../../../../api/UsersAPI";
import EditUser from "../../common/EditUser/EditUser";

export default function EditUserPage() {
  const initialUserData = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  };

  const fetchUserAndCompany = async (isNewUser, userId) => {
    const userData = !isNewUser
      ? { password: "", ...(await getUser(userId)).data }
      : initialUserData;

    return { userData, isNewUser };
  };

  return <EditUser fetchUserData={fetchUserAndCompany} />;
}
