import { APIService } from "./APIService";
import { AuthService, events } from "./AuthService";

/** @type {boolean} */
export const ENV_IS_PRODUCTION = process.env.NODE_ENV === 'production';

/**
 * API services config
 */
export const conf = {
  isProd: true,
  baseURL: `//portal.coolitsystems.com/api/v1/`,
  // baseURL: `//dev.portal.coolitsystems.com/api/v1/`,
  onTokenExpired: () => events.dispatchTokenExpired(),
};

/**
 * AuthService instance
 * @type {AuthService}
 */
export const authService = new AuthService({
  tokenKey: 'coolit_cp_session',
  onTokenExpired: conf.onTokenExpired,
});

/**
 * APIService instance
 * @type {APIService}
 */
export const apiService = new APIService({
  baseURL: conf.baseURL,
  isUseMultilang: false,
  isUseAuth: true,
  authService,
});
