/**
 * @module AuthService
 * @description Provides simple auth features based on cookie
 */

import Cookie from "js-cookie";

import { dispatchTokenExpired } from "./events";

/**
 * AuthService class
 */
export class AuthService {
  /**
   * Is auth service used
   * @type {boolean}
   */
  isUsed = true;

  /**
   * @type {null|string}
   */
  tokenKey = null;

  /**
   * On token expired callback
   */
  onTokenExpired = () => {};

  constructor({ isUsed = true, tokenKey = null, onTokenExpired = () => {} }) {
    this.isUsed = isUsed;
    this.tokenKey = tokenKey;
    this.onTokenExpired = onTokenExpired;
  }

  /**
   * @return {boolean}
   */
  get isLoggedIn() {
    return this.isUsed ? this.token !== null : true;
  }

  /**
   * Make login
   * @param {string} token
   */
  login(token = "") {
    this.setToken(token);
  }

  /**
   * Make logout
   */
  logout() {
    this.removeToken();
  }

  /**
   * Gets token
   * @return {string|null}
   */
  getToken() {
    const t = Cookie.get(this.tokenKey);
    return t === undefined ? null : t;
  }

  /**
   * @return {string|null}
   */
  get token() {
    return this.getToken();
  }

  /**
   * Sets the token
   * @param {string} token
   */
  setToken(token = "") {
    Cookie.set(this.tokenKey, token, {
      expires: 365,
    });
  }

  /**
   * Removes the token
   */
  removeToken() {
    Cookie.remove(this.tokenKey);
  }
}
