import { getStructure, getStructureAsCompany } from "../../api/FilesAPI";

export const FILES_REQUESTED = "FILES_REQUESTED";
export const FILES_STRUCTURE_LOADED = "FILES_STRUCTURE_LOADED";
export const FILES_RESET_DATA = "FILES_RESET_DATA";

const filesRequested = () => ({
  type: FILES_REQUESTED,
  payload: true,
});

const filesStructureLoaded = (data) => ({
  type: FILES_STRUCTURE_LOADED,
  payload: data,
});

export const filesResetData = () => ({
  type: FILES_RESET_DATA,
});

export const filesGetStructureAsync = (path = "") => (dispatch) => {
  dispatch(filesRequested());
  getStructure(path).then(({ data }) => dispatch(filesStructureLoaded(data)));
};

export const filesGetStructureAsCompany = (path = "/", companyId) => (dispatch) => {
  dispatch(filesRequested());
  getStructureAsCompany(path, companyId).then(({ data }) =>
    dispatch(filesStructureLoaded(data))
  );
};
