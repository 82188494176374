import { get, getList, remove } from "../../api/CompaniesAPI";

export const COMPANIES_REQUESTED = "COMPANIES_REQUESTED";
export const COMPANY_REQUESTED = "COMPANY_REQUESTED";
export const COMPANY_LOAD_SUCCESS = "COMPANY_LOAD_SUCCESS";
export const COMPANIES_LOAD_SUCCESS = "COMPANIES_LOAD_SUCCESS";
export const COMPANIES_RESET_DATA = "COMPANIES_RESET_DATA";
export const COMPANY_REMOVED = "COMPANY_REMOVED";

const companyLoadSuccess = (data) => ({
  type: COMPANY_LOAD_SUCCESS,
  payload: data,
});

const companiesRequested = () => ({
  type: COMPANIES_REQUESTED,
  payload: true,
});

const companiesLoadSuccess = (data) => ({
  type: COMPANIES_LOAD_SUCCESS,
  payload: data,
});

const companyRemoved = (id) => ({
  type: COMPANY_REMOVED,
  payload: id,
});

export const companyResetData = () => ({
  type: COMPANIES_RESET_DATA,
  payload: true,
});

export const getCompanyAsync = (id) => (dispatch) => {
  dispatch(companiesRequested());
  get(id).then(({ data }) => dispatch(companyLoadSuccess(data)));
};

export const getCompaniesAsync = () => (dispatch) => {
  dispatch(companiesRequested());
  getList().then(({ data }) => dispatch(companiesLoadSuccess(data)));
};

export const removeCompanyAsync = (id) => (dispatch) => {
  remove(id).then((res) => dispatch(companyRemoved(id)));
};
