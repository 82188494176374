/**
 * @module @components/lib/RoleLabel
 * @description The RoleLabel component.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const RoleLabel = ({ role }) => {
  switch (role) {
    case 'admin': return 'Administrator';
    case 'manager': return 'Manager';
    default: return 'User';
  }
};

RoleLabel.propTypes = {
  role: PropTypes.string,
};

RoleLabel.defaultProps = {

};

