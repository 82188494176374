export default function filterObject(obj, predicate) {
  let result = {},
    key;

  for (key in obj) {
    if (obj.hasOwnProperty(key) && key !== predicate) {
      result[key] = obj[key];
    }
  }

  return result;
}
