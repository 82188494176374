import { Switch, Route, Redirect } from "react-router-dom";
import { Login, Restore, NewPassword, ConfirmCode } from "../../pages/anonymous/Login";

export default function AnonymousRoutes() {
  return (
    <Switch>
      <Route exact path="/login/" component={Login} />
      <Route exact path="/login/confirm/" component={ConfirmCode} />
      <Route exact path="/password-reset/" component={Restore} />
      <Route exact path="/password-reset-complete/" component={NewPassword} />
      <Route path="*">
        <Redirect to="/login/" />
      </Route>
    </Switch>
  );
}
