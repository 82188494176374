import { Box, Typography, useTheme } from "@material-ui/core";
import React from "react";

export default function Footer() {
  const theme = useTheme();

  return (
    <Box
      width="100%"
      height="40px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.common.footerBg}
      color="white"
      p={1}
      mt={7}
    >
      <Typography>
        © CoolIT Systems
      </Typography>
    </Box>
  );
}
