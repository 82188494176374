/**
 * @module @libs/Ducks/extensions/StateExtension
 */

import { produce } from "immer";
import { DuckExtension } from "./DuckExtension";
import { Record } from "../Record";
import { put, takeEvery } from "@redux-saga/core/effects";

/** @typedef RequestKey {string} */

/***************************************************************************
 * Request statuses
 ***************************************************************************/
/** @typedef RequestStatus {string} */

/** @type {RequestStatus} */
export const REQUEST_STATUS_INITIAL = 'initial';

/** @type {RequestStatus} */
export const REQUEST_STATUS_RUNNING = 'running';

/** @type {RequestStatus} */
export const REQUEST_STATUS_SUCCESS = 'success';

/** @type {RequestStatus} */
export const REQUEST_STATUS_ERROR = 'error';

/***************************************************************************
 * Records
 ***************************************************************************/
/**
 * Request record
 */
export const RequestRecord = Record({
  status: REQUEST_STATUS_INITIAL,
  response: null,
  errors: null,
  errorsFlat: null,
  errorsFields: null,
  isInitial: true,
  isError: false,
  isRunning: false,
  isSuccess: false,
});

export function createExtension(namespace = '') {
  const reqHandlers = {};

  /***************************************************************************
   * Action types
   ***************************************************************************/
  const EMIT_REQUEST = `${namespace}/EMIT_REQUEST`;
  const REQUEST_START = `${namespace}/REQUEST_START`;
  const REQUEST_END = `${namespace}/REQUEST_END`;
  const REQUEST_RESET = `${namespace}/REQUEST_RESET`;

  /***************************************************************************
   * Actions
   ***************************************************************************/
  /**
   * Run request
   * @param {RequestKey} key
   * @return {{type: string, key: RequestKey}}
   */
  function reqStart(key = '') {
    return {
      type: REQUEST_START,
      key,
    }
  }

  /**
   * End request
   * @param {RequestKey} key
   * @param {boolean} isSuccess
   * @param {Array|Object|null} response
   * @param {Array|Object|null} errors
   * @return {{response: Array|Object|null, type: string, key: RequestKey, errors: Array|Object|null, status: RequestStatus}}
   */
  function reqEnd(key = '', isSuccess = true, response = [], errors = []) {
    return {
      type: REQUEST_END,
      key,
      status: isSuccess ? REQUEST_STATUS_SUCCESS : REQUEST_STATUS_ERROR,
      response,
      errors,
    }
  }

  /**
   * Request emit
   * @param {RequestKey} key
   * @param {Object} data
   * @return {{reqData: Object, type: string, key: RequestKey}}
   */
  function emitReq(key = '', data = {}) {
    return {
      type: EMIT_REQUEST,
      key,
      reqData: data,
    }
  }

  /**
   * Reset request
   * @param {RequestKey} key
   * @return {{type: string, key: RequestKey}}
   */
  function reqReset(key = '') {
    return {
      type: REQUEST_RESET,
      key,
    }
  }

  /***************************************************************************
   * Sagas
   ***************************************************************************/
  function* emitReqSaga({ key, reqData }) {
    if (!reqHandlers[key]) return;

    let isSuccess = true,
      response = null,
      errors = null;

    yield put(reqStart(key));

    try {
      let result = yield reqHandlers[key](reqData);
      isSuccess = result.isSuccess;
      response = result.response;
      errors = result.errors;
    } catch (e) {
      isSuccess = false;
      if (e.response) {
        errors = e.response.data;
        response = e.response;
      } else {
        errors = [e.message];
      }
    }

    yield put(reqEnd(key, isSuccess, response, errors));
  }

  /***************************************************************************
   * Selectors
   ***************************************************************************/
  /**
   * @param {Object} state
   * @param {RequestKey} key
   * @return {RecordItem}
   */
  const selectRequest = (state, key) => state[namespace][key];

  /**
   * @param {Object} state
   * @param {RequestKey} key
   * @return {boolean}
   */
  const selectIsRunning = (state, key) => selectRequest(state, key).status === REQUEST_STATUS_RUNNING;

  /**
   * @param {Object} state
   * @param {RequestKey} key
   * @return {boolean}
   */
  const selectIsSuccess = (state, key) => selectRequest(state, key).status === REQUEST_STATUS_SUCCESS;

  /**
   * @param {Object} state
   * @param {RequestKey} key
   * @return {boolean}
   */
  const selectIsError = (state, key) => selectRequest(state, key).status === REQUEST_STATUS_ERROR;

  /**
   * @param {Object} state
   * @param {RequestKey} key
   * @return {boolean}
   */
  const selectIsInitial = (state, key) => selectRequest(state, key).status === REQUEST_STATUS_INITIAL;

  /**
   * @param {Object} state
   * @param {RequestKey} key
   * @return {Object|Array}
   */
  const selectResponse = (state, key) => selectRequest(state, key).response;

  /**
   * @param {Object} state
   * @param {RequestKey} key
   * @return {Object|Array}
   */
  const selectErrors = (state, key) => selectRequest(state, key).errors;

  /***************************************************************************
   * Extension class
   ***************************************************************************/
  class RequestsExtension extends DuckExtension {
    statuses = {
      REQUEST_STATUS_INITIAL,
      REQUEST_STATUS_RUNNING,
      REQUEST_STATUS_SUCCESS,
      REQUEST_STATUS_ERROR,
    };

    records = {
      RequestRecord,
    };

    actionTypes = {
      EMIT_REQUEST,
      REQUEST_START,
      REQUEST_END,
      REQUEST_RESET,
    };

    actions = {
      reqStart,
      reqEnd,
      emitReq,
      reqReset,
    };

    sagas = [
      takeEvery(EMIT_REQUEST, emitReqSaga),
    ];

    selectors = {
      selectRequest,
      selectIsRunning,
      selectIsSuccess,
      selectIsError,
      selectIsInitial,
      selectResponse,
      selectErrors,
    };

    /**
     * Update request statuses flags
     * @param {RecordItem} r
     * @param {string} status
     * @return {RecordItem}
     * @private
     */
    _requestUpdateStatus(r, status) {
      r.status = status;
      r.isRunning = status === REQUEST_STATUS_RUNNING;
      r.isInitial = status === REQUEST_STATUS_INITIAL;
      r.isError = status === REQUEST_STATUS_ERROR;
      r.isSuccess = status === REQUEST_STATUS_SUCCESS;
      return r;
    }

    /**
     * Reducer
     * @param {Object} state
     * @param {string} type
     * @param {RequestKey} key
     * @param {RequestStatus} status
     * @param {Object|Array|null} response
     * @param {Object|Array|null} errors
     * @return {Object}
     */
    reducer(state, { type, key, status = REQUEST_STATUS_SUCCESS, response = null, errors = null }) {
      switch (type) {
        case REQUEST_START:
          return produce(state, s => {
            s[key] = produce(s[key], r => {
              r = this._requestUpdateStatus(r, REQUEST_STATUS_RUNNING);
              r.errors = null;
              r.response = null;
            })
          });

        case REQUEST_END:
          return produce(state, s => {
            s[key] = produce(s[key], r => {
              r = this._requestUpdateStatus(r, status);
              r.errors = errors;
              if (errors) {
                r.errorsFlat = this.renderErrorsFlat(errors);
                r.errorsFields = this.renderErrorsByFields(errors);
              }
              r.response = response;
            })
          });

        case REQUEST_RESET:
          return produce(state, s => {
            s[key] = new RequestRecord();
          });

        default:
          return state;
      }
    }

    /**
     * Makes request handler out object
     * @param {Object.<string, *>} defaults
     * @return {{response: null, errors: null, isSuccess: boolean}}
     */
    makeHandlerOut(defaults = {}) {
      return { isSuccess: false, response: null, errors: null, ...defaults, };
    }

    /**
     * Add request handler
     * @param {RequestKey} key - request key
     * @param {function} fn - handler
     */
    addHandler(key, fn) {
      reqHandlers[key] = fn;
    }

    /**
     * Render errors to the simple array
     * @param {Object<string, string>|string[]} data
     * @return {string[]}
     */
    renderErrorsFlat(data = {}) {
      if (data instanceof Array) {
        return [...data];
      }
      let out = [];
      Object.values(data).forEach(el => {
        if (el instanceof Array) {
          out = [
            ...out,
            ...el
          ]
        } else {
          out.push(el);
        }
      });
      return out;
    }

    /**
     * Render errors to the object, sort them by fields
     * @param {Object<string, string>|string[]} data
     * @return {Object.<string, string>}
     */
    renderErrorsByFields(data = {}) {
      let out = {
        form: [],
      };

      if (data instanceof Array) {
        data.forEach(el => {
          if (el instanceof Array) {
            out.form = [
              ...out.form,
              ...el
            ];
          } else {
            out.form.push(el);
          }
        });
      } else {
        Object.keys(data).forEach(key => {
          if (key === 'non_field_errors') {
            out.form = [
              ...out.form,
              ...data[key],
            ];
            return;
          }
          if (key === 'detail') {
            out.form.push(data[key]);
          }
          if (!out[key]) out[key] = [];
          if (data[key] instanceof Array) {
            out[key] = [
              ...out[key],
              ...data[key],
            ]
          } else {
            out[key].push(data[key]);
          }
        });
      }
    }
  }

  return new RequestsExtension();
}
