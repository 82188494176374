import {
  Box,
  Container,
} from "@material-ui/core";
import React  from "react";
import PageHeader from "../../../shared/PageHeader";
import { useSelector } from "react-redux";

import { UsersList } from "../../../shared/Users/UsersList";

export default function Users() {
  const currentUser = useSelector(({ auth }) => auth.authData);

  return (
    <Box>
      <PageHeader left="User Management" right={currentUser.companyName} />
      <Container>
        <UsersList />
      </Container>
    </Box>
  );
}
