import { getList, get, remove } from "../../api/UsersAPI";
import { setSuccessMessage as setSuccessMessageAction } from "./SnackbarActions";

export const USERS_REQUESTED = "USERS_REQUESTED";
export const USERS_LOADING_SUCCESS = "USERS_LOADING_SUCCESS";
export const USER_LOADING_SUCCESS = "USER_LOADING_SUCCESS";
export const USERS_RESET_DATA = "USERS_RESET_DATA";
export const USERS_REMOVE = "USERS_REMOVE";

const usersRequested = () => ({
  type: USERS_REQUESTED,
  payload: true,
});

const userLoadingSuccess = (data) => ({
  type: USER_LOADING_SUCCESS,
  payload: data,
});

const usersLoadingSuccess = (data) => ({
  type: USERS_LOADING_SUCCESS,
  payload: data,
});

const usersRemove = (id) => ({
  type: USERS_REMOVE,
  payload: id,
});

export const usersResetData = () => ({
  type: USERS_RESET_DATA,
});

export const getUsersAsync = () => (dispatch) => {
  dispatch(usersRequested());
  getList().then(({ data }) => dispatch(usersLoadingSuccess(data)));
};

export const removeUserAsync = (id) => (dispatch) => {
  remove(id).then(() => {
    dispatch(usersRemove(id));
    dispatch(setSuccessMessageAction("User was successfully deleted"));
  });
};

export const getUserAsync = (id) => (dispatch) => {
  dispatch(usersRequested());
  get(id).then(({ data }) => dispatch(userLoadingSuccess(data)));
};
