import { login } from "../../api/AuthAPI";
import { getMe } from "../../api/UsersAPI";
import { authService } from "../../api/services";

export const AUTH_REQUESTED = "AUTH_REQUESTED";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILED = "AUTH_LOGIN_FAILED";
export const AUTH_GET_ME_SUCCESS = "AUTH_GET_ME_SUCCESS";
export const AUTH_DATA_CHANGED = "AUTH_DATA_CHANGED";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

const authRequested = () => ({
  type: AUTH_REQUESTED,
  payload: true,
});

export const authDataChanged = () => ({
  type: AUTH_DATA_CHANGED,
});

export const authLogout = () => ({
  type: AUTH_LOGOUT,
});

const authGetMeSuccess = (data) => ({
  type: AUTH_GET_ME_SUCCESS,
  payload: data,
});

const authDataLoaded = (data) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: data,
});

const authLoginFailed = (error) => ({
  type: AUTH_LOGIN_FAILED,
  payload: error,
});

export const loginAction = (email, password) => (dispatch) => {
  dispatch(authRequested());

  login(email, password)
    .then(() => {
      return getMe();
    })
    .then(({ data }) => dispatch(authDataLoaded(data)))
    .catch((error) => dispatch(authLoginFailed(error)));
};

export const loginByReadyToken = (token) => (dispatch) => {
  dispatch(authRequested());

  authService.setToken(token);

  getMe()
    .then(({ data }) => dispatch(authDataLoaded(data)))
    .catch((error) => dispatch(authLoginFailed(error)));
};

export const getMeAction = (dispatch) => {
  dispatch(authRequested());
  getMe().then(({ data }) => dispatch(authGetMeSuccess(data))).catch(e => console.log('-----------------', e));
};

export const updateAuthData = (history) => (dispatch) => {
  dispatch();
};
