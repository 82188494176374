/**
 * @module @components/lib/Breadcrumbs
 * @description The Breadcrumbs component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Breadcrumbs as _Breadcrumbs } from "@material-ui/core";
import { ActionLink, RouteLink } from "./Link";

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const Breadcrumbs = ({ current, items }) => {
  return (
    <_Breadcrumbs separator="›">
      {items.map((el) => el.id === current ? (
        <Typography onClick={el.onClick} key={el.id}>{el.label}</Typography>
      ) : el.onClick ? (
        <ActionLink onClick={el.onClick} key={el.id}>{el.label}</ActionLink>
      ) : (
        <RouteLink to={el.to} key={el.id}>{el.label}</RouteLink>
      ))}
    </_Breadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.node,
    onClick: PropTypes.func,
    to: PropTypes.string,
  })
};

Breadcrumbs.defaultProps = {
  items: [],
};

