import { Documents } from "../../pages/common/Documents";
import { Route, Switch } from "react-router";
import { Login, Restore, NewPassword } from "../../pages/anonymous/Login";
import Profile from "../../pages/common/Profile";
import { Redirect } from "react-router-dom";

export default function UserRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Documents} />
      <Route exact path="/profile/" component={Profile} />
      <Route exact path="/login/" component={Login} />
      <Route exact path="/password-reset/" component={Restore} />
      <Route exact path="/password-reset-confirm/" component={NewPassword} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
