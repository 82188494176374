/**
 * @module @libs/Ducks/Record
 * @description helps to
 * - predefine the items structure
 * - set default values for each item
 */

import {immerable} from "immer"

/**
 * Creates class for making new items
 * @param {Object<string, *>} template - the item structure template
 * @example <caption>Example of creating new Item class and creating new items</caption>
 * const TestRecord = Record({
 *   title: "Test",
 *   isShow: false,
 * });
 * const item1 = new TestRecord();
 * const item2 = new TestRecord({
 *   title: "item2",
 * });
 * @return {{new(*=): RecordItem, prototype: RecordItem}}
 */
export function Record(template = {}) {
  /**
   * Class RecordItem
   * @description Creates new items
   */
  return class RecordItem {
    /**
     * Add immer.js compatibility
     * @type {boolean}
     */
    [immerable] = true;

    /**
     * @param {Object<string, *>} values
     */
    constructor(values = {}) {
      let merged = {
        ...template,
        ...values,
      };
      Object.keys(merged).forEach(k => {
        this[k] = merged[k];
      });
    }
  }
}
