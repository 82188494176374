import {
  Box,
  Container, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionLink, RouteLink } from "../../../lib/Link";
import PageHeader from "../../../shared/PageHeader";
import Button, { useButtonStyles } from "../../../lib/Button";

import {
  getCompaniesAsync,
  removeCompanyAsync,
} from "../../../../store/actions/CompaniesActions";

import DataLoader from "../../../shared/DataLoader";
import PopupConfirm from "../../../shared/PopupConfirm/PopupConfirm";
import { useHistory } from "react-router";
import { COOLIT_COMPANY_NAME } from "../../../../config";
import { getUsersAsync } from "../../../../store/actions/UsersActions";

const useTableStyles = makeStyles({
  row: {
    "&:hover": {
      background: "rgba(189, 189, 189, 0.2)",
    },
  },
});

export default function Accounts() {
  const [companyForDeleting, setCompanyForDeleting] = useState(null);
  const companiesList = useSelector(({ companies }) => companies.companiesData);
  const usersList = useSelector(({ users }) => users.usersData);
  const history = useHistory();
  const dispatch = useDispatch();
  const btnClasses = useButtonStyles();
  const tableClasses = useTableStyles();

  useEffect(() => {
    dispatch(getUsersAsync());
    dispatch(getCompaniesAsync());
  }, []);

  const onAddUserBtnClick = () => {
    history.push("/company/edit/new-company");
  };

  const onCancel = () => {
    setCompanyForDeleting(null);
  };

  const onAgreement = () => {
    if (companyForDeleting) {
      setCompanyForDeleting(null);
      dispatch(removeCompanyAsync(companyForDeleting));
    }
  };

  const getCompanyManagerName = (companyID) => {
    let managers = usersList.filter(el => el.role === 'manager' && el.company === companyID);
    return managers.length ? [managers[0].firstName, managers[0].lastName].filter(l => l.length).join(' ') : '-';
  };

  return (
    <Fragment>
      <PageHeader left="Partners" right={COOLIT_COMPANY_NAME} />
      {companiesList && usersList ? (
        <Box>
          <Container>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button onClick={onAddUserBtnClick} className={btnClasses.root}>
                Add a new partner
              </Button>
            </Box>
            <Box my={3}>
              <TableContainer>
                <Table aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Partner</TableCell>
                      <TableCell align="left">Folder</TableCell>
                      <TableCell align="left">Country</TableCell>
                      <TableCell align="left">Manager</TableCell>
                      <TableCell align="center">Number of users</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companiesList.map((company) => (
                      <TableRow className={tableClasses.row} key={company.id}>
                        <TableCell align="left">{company.name}</TableCell>
                        <TableCell align="left">
                          {company.rootDirPath}
                        </TableCell>
                        <TableCell align="left">{company.country}</TableCell>
                        <TableCell align="left">{getCompanyManagerName(company.id)}</TableCell>
                        <TableCell align="center">{usersList.filter(el => el.company === company.id).length}</TableCell>
                        <TableCell align="right">
                          <RouteLink to={`/company/${company.id}/`}>
                            View
                          </RouteLink>
                          <RouteLink to={`/company/edit/${company.id}/`}>
                            Edit
                          </RouteLink>
                          <ActionLink
                            onClick={() => setCompanyForDeleting(company.id)}
                          >
                            Delete
                          </ActionLink>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Container>
          <PopupConfirm
            open={Boolean(companyForDeleting)}
            onCancel={onCancel}
            onAgreement={onAgreement}
            title="Delete Confirmation"
            content="You have selected to delete this partner."
          />
        </Box>
      ) : (
        <DataLoader />
      )}
    </Fragment>
  );
}
