import produce from "immer";
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_REQUESTED,
  AUTH_LOGIN_FAILED,
  AUTH_GET_ME_SUCCESS,
  AUTH_DATA_CHANGED,
} from "../actions/AuthActions";

const initialState = {
  error: null,
  isLoading: false,
  authData: null,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_REQUESTED:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case AUTH_LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft.error = null;
        draft.isLoading = false;
        draft.authData = { ...action.payload };
      });
    case AUTH_LOGIN_FAILED:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = action.payload;
      });
    case AUTH_GET_ME_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.authData = action.payload;
      });
    case AUTH_DATA_CHANGED:
      return initialState;
    default:
      return state;
  }
}
