import {
  Box,
  Container,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import Heading from "../lib/Heading";

const useTypographyStyles = makeStyles((theme) => ({
  subhead: {
    color: theme.palette.text.primary,
    fontSize: 15,
    marginTop: 20,
    marginBottom: 30,
  },

  desc: {
    color: theme.palette.common.dark1,
    fontSize: 15,
    marginBottom: 30,
  },
}));

export default function LoginLayout({ heading, subhead, desc, children }) {
  const theme = useTheme();
  const classes = useTypographyStyles();

  return (
    <Container>
      <Box
        maxWidth="500px"
        margin="0 auto"
        mt="30px"
        fontFamily="'Montserrat', sans-serif"
      >
        <Heading color={theme.palette.text.primary} fontSize={48}>
          {heading}
        </Heading>

        <Typography className={classes.subhead} paragraph>
          {subhead}
        </Typography>
        <Typography variant="body1" className={classes.desc}>
          {desc}
        </Typography>
        {children}
      </Box>
    </Container>
  );
}
