import React from "react";

import UserRoutes from "./User";
import ManagerRoutes from "./Manager";
import AdminRoutes from "./Admin";
import AnonymousRoutes from "./Anonymous";

export default function AppRoutes({ role = null }) {
  switch (role) {
    case "user":
      return <UserRoutes />;
    case "manager":
      return <ManagerRoutes />;
    case "admin":
      return <AdminRoutes />;
    default:
      return <AnonymousRoutes />;
  }
}
