import produce from "immer";
import {
  FILES_REQUESTED,
  FILES_STRUCTURE_LOADED,
  FILES_RESET_DATA,
} from "../actions/FilesAction";

const initialState = {
  isLoading: false,
  filesData: null,
};

export default function FilesReducer(state = initialState, action) {
  switch (action.type) {
    case FILES_REQUESTED:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.filesData = null;
      });
    case FILES_STRUCTURE_LOADED:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.filesData = action.payload;
      });

    default:
      return state;
  }
}
