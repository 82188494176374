import LoginLayout from "../../../layouts/LoginLayout";
import React, { useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import Button from "../../../lib/Button";
import { RouteLink } from "../../../lib/Link";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { requestPasswordReset } from "../../../../api/AuthAPI";
import { Redirect } from "react-router";

export default function Restore() {
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const { handleSubmit, register, errors } = useForm();
  const auth = useSelector((state) => state.auth.authData);

  const submitSuccessComponent =
    submitSuccess === true ? (
      <Typography style={{ color: "green" }}>Email sent</Typography>
    ) : submitSuccess === false ? (
      <Typography color="error">Email not found</Typography>
    ) : null;

  const onSubmit = (data) => {
    requestPasswordReset(
      data.email,
      window.location.protocol + '//' + window.location.host + '/password-reset-complete/'
    )
      .then(() => setSubmitSuccess(true))
      .catch(() => setSubmitSuccess(false));
  };

  return auth ? (
    <Redirect to="/" />
  ) : (
    <LoginLayout
      heading="Partner Portal"
      subhead=""
      desc="Please enter your Customer Portal email"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <TextField
            error={Boolean(errors.email)}
            fullWidth
            id="email"
            label="Email"
            type="email"
            name="email"
            defaultValue=""
            helperText={(errors.email && "This field is required") || ""}
            variant="outlined"
            inputRef={register({ required: true })}
          />
        </Box>

        <Box my="30px">
          <Button type="submit">Send</Button>
        </Box>

        <Box component="a" my="30px" display="block">
          <RouteLink to="/login/">Login</RouteLink>
        </Box>
      </form>

      {submitSuccessComponent}
    </LoginLayout>
  );
}
