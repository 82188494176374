/**
 * @module components/shared/Files/Files
 * @description The Files component.
 */

import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import * as FilesDuck from "../../../store/ducks/files.duck";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { SearchBox } from "./SearchBox";
import DataLoader from "../DataLoader";
import { FilesTable as Table } from "./Table";
import { Tree } from './Tree';
import { Breadcrumbs } from '../../lib/Breadcrumbs';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const Files = ({ company }) => {
  const [isSearch, setIsSearch] = useState(false),
    isSearchRef = useRef(isSearch),
    [searchString, setSearchString] = useState(''),
    [dirID, setDirID] = useState(null),
    [treeState, setTreeState] = useState(['root']),
    theme = useTheme(),
    isMobile = useMediaQuery(theme.breakpoints.down('sm')),

    dispatch = useDispatch(),
    mainItems = useSelector(FilesDuck.selectItems),
    searchItems = useSelector(FilesDuck.selectSearchItems),
    isLoading = useSelector(FilesDuck.selectIsLoading),

    items = isSearch ? searchItems : mainItems,
    renderItems = items.filter(el => el.parent === dirID),
    dirData = dirID !== null ? items.find(el => el.id === dirID) : null;

  // utils
  const openTreeForItem = (id) => {
    let ids = [];
    const handleItem = (item) => {
      if (!item) return;
      ids.push(item.id);
      handleItem(items.find(el => el.id === item.parent));
    };
    handleItem(items.find(el => el.id === id));
    setTreeState(['root', ...[...treeState, ...ids].filter((value, index, self) => self.indexOf(value) === index)]);
  };

  // effects
  useEffect(() => {
    dispatch(FilesDuck.loadItems("/", company));
  }, []);

  useEffect(() => {
    isSearchRef.current = isSearch;
  }, [isSearch]);

  useEffect(() => {
    openTreeForItem(dirID);
  }, [dirID, items]);

  // load items handlers
  const handleLoadRoot = () => {
    dispatch(FilesDuck.loadItems("/", company));
    setIsSearch(false);
    setSearchString('');
    setDirID(null);
  };

  const handleLoadSearch = (search) => {
    dispatch(FilesDuck.searchItems(search, company));
    setIsSearch(true);
    setSearchString(search);
    setDirID(null);
  };

  const handleLoadSubItems = (path, parentID) => {
    dispatch(FilesDuck.loadSubItems(path, parentID, isSearchRef.current, company));
    setDirID(parentID);
  };

  const handleSearch = (search) => {
    search.length ? handleLoadSearch(search) : handleLoadRoot();
  };

  const handleClickTreeFolder = (path, id) => {
    setIsSearch(false);
    setSearchString('');
    handleLoadSubItems(path, id);
    openTreeForItem(id);
  };

  const handleClickTableFilter = (path, id) => {
    handleLoadSubItems(path, id);
  };

  // build the breadcrumbs
  let breadcrumbs = [
    {
      id: `root`,
      label: `Root`,
      onClick: () => handleLoadRoot(),
    }
  ];
  if (isSearch) {
    breadcrumbs.push({
      id: `search`,
      label: `Search "${searchString}"`,
      onClick: () => handleSearch(searchString),
    });
  }

  if (dirData) {
    let tempBreadcrumbs = [{
        id: dirData.id,
        label: dirData.viewName,
        onClick: () => handleClickTreeFolder(dirData.url, dirData.id),
      }],
      tmp = dirData;
    while (tmp.parent !== null) {
      let nextTmp = items.find(el => el.id === tmp.parent);
      tempBreadcrumbs.push({
        id: nextTmp.id,
        label: nextTmp.viewName,
        onClick: () => handleClickTreeFolder(nextTmp.url, nextTmp.id),
      });
      tmp = nextTmp;
    }
    breadcrumbs = [...breadcrumbs, ...tempBreadcrumbs.reverse()];
  }

  return (
    <Box my={3}>
      <Container>
        <Grid container spacing={2}>
          {!isMobile && (
            <Grid item xs={3}>
              <Tree
                handleClickFolder={handleClickTreeFolder}
                items={mainItems}
                expanded={treeState}
                selected={dirID}
                handleGoRoot={handleLoadRoot}
                handleExpand={(_, s) => setTreeState(s)} />
            </Grid>
          )}
          <Grid item xs={isMobile ? 12 : 9}>
            <Box mb={1}>
              <SearchBox onSubmit={handleSearch} />
            </Box>
            <Box mb={2}>
              <Breadcrumbs
                current={dirID || `root-${isSearch ? 0 : 1}`}
                items={breadcrumbs}
              />
            </Box>
            {isLoading ? (
              <DataLoader />
            ) : (
              <Fragment>
                {isSearch && renderItems.length === 0 && (
                  <Box>
                    <Typography>None of your files or folders matched this search.</Typography>
                  </Box>
                )}
                {!isSearch && renderItems.length === 0 && (
                  <Box>
                    <Typography>That folder is empty.</Typography>
                  </Box>
                )}
                <Table
                  handleClickFolder={handleClickTableFilter}
                  items={renderItems} />
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

Files.propTypes = {
  company: PropTypes.number,
};

Files.defaultProps = {
  company: null,
};

