export const SNACKBAR_SET_SUCCESS_MESSAGE = "SNACKBAR_SET_SUCCESS_MESSAGE";
export const SNACKBAR_SET_MESSAGE = "SNACKBAR_SET_MESSAGE";
export const SNACKBAR_CLOSE = "SNACKBAR_CLOSE";

const snackbarMessage = (message, type = 'success') => ({
  type: SNACKBAR_SET_MESSAGE,
  payload: message,
  messageType: type,
});


const snackbarSuccessMessage = (message) => ({
  type: SNACKBAR_SET_SUCCESS_MESSAGE,
  payload: message,
});

const snackbarClose = () => ({
  type: SNACKBAR_CLOSE,
});

export const setSuccessMessage = (message = null) => (dispatch) => {
  dispatch(snackbarSuccessMessage(message));
  setTimeout(() => dispatch(snackbarClose()), 4000);
};

export const setMessage = (message = null, type = 'success') => (dispatch) => {
  dispatch(snackbarMessage(message, type));
  setTimeout(() => dispatch(snackbarClose()), 4000);
};
