import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

export default function DataLoader() {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      py={5}
    >
      <CircularProgress />
    </Box>
  );
}
