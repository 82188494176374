import {
  Box,
  Container,
} from "@material-ui/core";
import React from "react";
import PageHeader from "../../../shared/PageHeader";
import { COOLIT_COMPANY_NAME } from "../../../../config";
import { UsersList } from "../../../shared/Users/UsersList";


export default function Users() {
  return (
    <Box>
      <PageHeader left="User Management" right={COOLIT_COMPANY_NAME} />
      <Container>
        <UsersList />
      </Container>
    </Box>
  );
}
