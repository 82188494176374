/**
 * @module @libs/Ducks/extensions/DuckExtension
 */

/**
 * Duck extension class
 */
export class DuckExtension {
  /**
   * @abstract
   * @type {Object.<string, string>}
   */
  actionTypes = {};

  /**
   * @abstract
   * @type {Object.<string, function>}
   */
  actions = {};

  /**
   * @abstract
   * @type {function[]}
   */
  sagas = [];

  /**
   * @abstract
   * @type {Object.<string, function>}
   */
  selectors = {};

  /**
   * Reducer function
   * @abstract
   * @param {Object} state
   * @param {Object} action
   * @return {Object}
   */
  reducer(state, action) {
    switch (action.type) {
      default:
        return state;
    }
  }
}

/**
 * Example create extension function
 * @param namespace
 * @return {NewExtension}
 */
function createExtension(namespace = '') {
  const TEST = `${namespace}/TEST`;
  class NewExtension extends DuckExtension {
    actionTypes = {
      TEST,
    };

    reducer(state, action) {
      return super.reducer(state, action);
    }
  }
  return new NewExtension();
}
