/**
 * @module @components/shared/Files/Table
 * @description The Table component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, TableBody, TableCell, TableContainer, TableRow, Table } from "@material-ui/core";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FolderIcon from '@material-ui/icons/Folder';
import { ActionLink } from "../../lib/Link";
import { ItemIcon } from "./ItemIcon";

const useTableStyles = makeStyles({
  root: {
    width: 530,
    cursor: "pointer",
    "&:hover": {
      background: "rgba(189, 189, 189, 0.2)",
    },
  },

  iconCell: {
    paddingLeft: 0,
  },

  nameCell: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 500,
  },

  linkCell: {
    color: "black",
    "&:visited": {
      color: "black",
    },
  },
});

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const FilesTable = ({ items, handleClickFolder }) => {
  const cellsClasses = useTableStyles();

  const renderRows = (items) => {
    return items.map((row) => (
      <TableRow
        onClick={row.type === "folder" ? () => handleClickFolder(row.url, row.id) : () => {}}
        key={row.name}
        classes={{ root: cellsClasses.root }}>
        <TableCell
          component="th"
          scope="row"
          style={{ width: '2.5rem', padding: `0 0 0 1rem`, lineHeight: 0, }}
          className={cellsClasses.cells}
        >
          <ItemIcon name={row.viewName} type={row.type} />
        </TableCell>
        <TableCell colSpan={row.type === "file" ? 1 : 2} classes={{ root: cellsClasses.nameCell }}>
          <Box display="flex" alignItems="center">
            {row.viewName}
          </Box>
        </TableCell>
        <TableCell align="left">
          {row.type === "file" && (
            <ActionLink target="_blank" href={row.downloadLink}>Download</ActionLink>
          )}
        </TableCell>
      </TableRow>
    ))
  };

  return (
    <TableContainer>
      <Table aria-label="a dense table">
        <TableBody>
          {renderRows(items.filter(el => el.type === 'folder'))}
          {renderRows(items.filter(el => el.type === 'file'))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

FilesTable.propTypes = {
  items: PropTypes.array,
  handleClickFolder: PropTypes.func,
};

FilesTable.defaultProps = {

};

