/** @type {string} */
export const TOKEN_EXPIRED_EVENT_NAME = `@react/coolit/tokenExpired`;

/**
 * Dispatches token expired event on window
 */
export function dispatchTokenExpired() {
  window.dispatchEvent(new CustomEvent(TOKEN_EXPIRED_EVENT_NAME, {
    detail: {},
  }));
}

/**
 * @param {function} fn
 */
export function onTokenExpired(fn = () => {}) {
  window.addEventListener(TOKEN_EXPIRED_EVENT_NAME, fn);
}
