/**
 * @module @components/shared/Files/ItemIcon
 * @description The ItemIcon component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FileIcon, defaultStyles } from 'react-file-icon';

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const ItemIcon = ({ type, name, iconProps }) => {
  if (type === 'folder') {
    return (
      <svg style={{margin: "0 auto", display: "block"}} width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="10" height="4" rx="1" fill="#FDB82B"/>
        <rect y="2" width="20" height="14" rx="1" fill="#FED972"/>
        <rect y="15" width="20" height="1" rx="0.5" fill="#F1A64F"/>
      </svg>
    );
  }

  const nameParts = name.split('.'),
    extension = nameParts[nameParts.length - 1],
    defStyle = defaultStyles[extension] || {};

  return <FileIcon extension={extension} fold {...defStyle} />;
};

ItemIcon.propTypes = {
  type: PropTypes.oneOf(['file', 'folder']),
  name: PropTypes.string.isRequired,
  iconProps: PropTypes.any,
};

ItemIcon.defaultProps = {
  type: 'folder',
  iconProps: {},
};

