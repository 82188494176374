import { ThemeProvider } from "@material-ui/core/styles";
import { Box, createMuiTheme, CssBaseline, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';

import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import AppRoutes from "./components/shared/Routes/AppRoutes";
import { authService } from "./api/services";
import { onTokenExpired } from "./api/services/AuthService/events";
import DataLoader from "./components/shared/DataLoader";
import { useCallback, useEffect } from "react";
import { authLogout, getMeAction } from "./store/actions/AuthActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useIdleTimer } from "./hooks/useIdleTimer";

const globalOverrides = {
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: "100%",
          height: "100%",
        },
        body: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
      },
    },
  },

  palette: {
    common: {
      dark1: "#000000",
      dark2: "#0D082F",
      light: "#FFFFFF",
      footerBg: "#13122F",
      headerBg: "#FFFFFF",
      actionHover: "#E3E1E1",
      tabIndicator: "#658ECB",
    },

    primary: {
      main: "#4E4E4E",
    },
    secondary: {
      main: "#A9A9A9",
    },
    text: {
      primary: "#000000",
      secondary: "#4E4E4E",
    },
    background: {
      default: "#FFFFFF",
      paper: "#F9F9F9",
    },
  },

  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 540,
      md: 768,
      lg: 1570,
      xl: 1920,
    },
  },
};

const mainTheme = createMuiTheme(globalOverrides);

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
}));

function App() {
  const classes = useStyles();
  const authData = useSelector(({ auth }) => auth.authData);
  const { message, isOpen, type: messageType } = useSelector(({ snackbar }) => snackbar);
  const dispatch = useDispatch();
  const token = useSelector(() => authService.getToken());
  const hasData = Boolean(!authData && token);
  const history = useHistory();

  const getMeData = useCallback(() => {
    dispatch(getMeAction);
  }, [dispatch]);

  const logout = useCallback(() => {
    authService.removeToken();
    dispatch(authLogout());
    history.push('/login');
  }, [dispatch]);

  useEffect(() => {
    onTokenExpired(() => {
      logout();
    });
  }, []);

  useEffect(() => {
    if (token && !authData) {
      getMeData();
    }
  }, [authData]);

  useIdleTimer(60 * 60, logout);

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box
        id="wrapper"
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <Header />
        <div className={classes.offset} />
        <Box id="content" flex={1}>
          {!token ? <AppRoutes role={null} /> : hasData ? (
            <DataLoader />
          ) : (
            <AppRoutes role={authData?.role || null} />
          )}
        </Box>
        <Footer />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={Boolean(isOpen)}>
          <Alert severity={messageType} variant="filled">
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
}

export default App;
