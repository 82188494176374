/**
 * @description Connect all sagas
 */

import { all } from 'redux-saga/effects';

import * as FilesDuck from '../ducks/files.duck';

const ducks = [
  FilesDuck,
];

export default function* saga() {
  yield all([
    ...ducks.map((duck) => duck.saga()),
  ]);
}
