/**
 * @description Configure store
 */

import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import thunk from "redux-thunk";

import _middlewares from './middlewares';
import reducers from './reducers';
import saga from './saga';

const sagaMiddleware = createSagaMiddleware();

/**
 * Configure store function
 * @return {Store}
 */
function configureStore() {
  let middlewares = [sagaMiddleware, thunk, ..._middlewares],
    composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
      collapsed: true,
    });

    middlewares = [logger, ...middlewares];

    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const store = createStore(
    reducers(),
    {},
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(saga);
  return store;
}
export default configureStore;
