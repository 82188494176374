import produce from "immer";
import {
  USERS_REQUESTED,
  USERS_LOADING_SUCCESS,
  USERS_RESET_DATA,
  USER_LOADING_SUCCESS,
  USERS_REMOVE,
} from "../actions/UsersActions";

const initialState = {
  isUsersLoading: false,
  isUserLoading: false,
  userData: null,
  usersData: null,
};

export default function UsersReducer(state = initialState, action) {
  switch (action.type) {
    case USERS_REQUESTED:
      return produce(state, (draft) => {
        draft.isUsersLoading = true;
        draft.isUserLoading = true;
        draft.userData = null;
        draft.usersData = null;
      });
    case USERS_LOADING_SUCCESS:
      return produce(state, (draft) => {
        draft.isUsersLoading = false;
        draft.isUsersLoading = false;
        draft.usersData = action.payload;
      });

    case USER_LOADING_SUCCESS:
      return produce(state, (draft) => {
        draft.isUserLoading = false;
        draft.userData = action.payload;
      });
    case USERS_REMOVE:
      return produce(state, (draft) => {
        const id = action.payload;
        draft.usersData = draft.usersData.filter((user) => user.id !== id);
      });
    case USERS_RESET_DATA:
      return initialState;

    default:
      return state;
  }
}
