/**
 * @module @components/pages/common/Documents/Documentsx
 * @description The Documents component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import PageHeader from "../../../shared/PageHeader";
import { Files } from "../../../shared/Files";

/**
 * @param {Object.<string, *>} props
 * @constructor
 */
export const Documents = (props) => {
  const authData = useSelector(({ auth }) => auth.authData);

  return (
    <Box>
      <PageHeader left="Documents" right={authData.companyName} />
      <Files />
    </Box>
  );
};

Documents.propTypes = {

};

Documents.defaultProps = {

};

