import { apiService } from "./services";

/**  @type ServerToLocalMap */
const SERVER_TO_LOCAL_MAP = {
// server key         local key              type
  "id":               'id',               // number
  "email":            "email",            // string
  "first_name":       "firstName",        // string
  "last_name":        "lastName",         // string
  "role":             "role",             // string: admin, manager, user
  "company":          "company",          // number
  "company_name":     "companyName",      // string
  "company_domains":  "companyDomains"    // string | null
};

/**  @type LocalToServerMap */
const LOCAL_TO_SERVER_MAP = apiService.createLocalToServerItemMap(SERVER_TO_LOCAL_MAP);

/**  @type function(ServerItemData): LocalItemData */
const serverToLocal = apiService.createServerToLocalTransformer(SERVER_TO_LOCAL_MAP);

/**  @type function(LocalItemData): ServerItemData */
const localToServer = apiService.createLocalToServerTransformer({
  ...LOCAL_TO_SERVER_MAP,
  password: "password",
});

/**
 * Gets current user data
 * @return {Promise<any>}
 */
export function getMe() {
  return apiService.get('/users/me/', {}, true, {
    transformResponse: (raw) => serverToLocal(JSON.parse(raw)),
  });
}

/**
 * Updates current user fields
 * @param {Object.<string, *>} fields
 * @return {Promise<any>}
 */
export function updateMe(fields = {}) {
  return apiService.patch('/users/me/', {
    ...localToServer({
      ...fields,
      ...(fields.email ? { email: fields.email.toLowerCase() } : {})
    }),
  }, {}, true, {
    transformResponse: (raw) => serverToLocal(JSON.parse(raw)),
  })
}

/**
 * Changes current user password
 * @param {string} oldPassword
 * @param {string} newPassword
 * @return {Promise<any>}
 */
export function changeMyPassword(oldPassword, newPassword) {
  return apiService.patch('/users/me/', {
    password: newPassword,
    old_password: oldPassword,
  }, {}, true)
}

/**
 * Creates a new user
 * @param {Object.<string, *>} fields
 * @return {Promise<any>}
 */
export function create(fields = {}) {
  return apiService.post('/users/', {
    ...localToServer({
      ...fields,
      ...(fields.email ? { email: fields.email.toLowerCase() } : {})
    }),
  }, {}, true, {
    transformResponse: (raw) => serverToLocal(JSON.parse(raw)),
  });
}

/**
 * Updates user by ID
 * @param {ServerID} userID
 * @param {Object.<string, *>} fields
 * @return {Promise<any>}
 */
export function update(userID, fields = {}) {
  return apiService.patch(`/users/${userID}/`, {
    ...localToServer({
      ...fields,
      ...(fields.email ? { email: fields.email.toLowerCase() } : {})
    }),
  }, {}, true, {
    transformResponse: (raw) => serverToLocal(JSON.parse(raw)),
  });
}

/**
 * Removes user by ID
 * @param {ServerID} userID
 * @return {Promise<any>}
 */
export function remove(userID) {
  return apiService.del(`/users/`, {
    id: userID,
  }, {}, true);
}

/**
 * Gets user by ID
 * @param {ServerID} userID
 * @return {Promise<any>}
 */
export function get(userID) {
  return apiService.get(`/users/${userID}/`, {}, true, {
    transformResponse: (raw) => serverToLocal(JSON.parse(raw)),
  });
}

/**
 * Gets users' list
 * @return {Promise<any>}
 */
export function getList() {
  return apiService.get(`/users/`, {}, true, {
    transformResponse: (raw) => JSON.parse(raw).map(el => {
      let out = serverToLocal(el);
      return {
        ...out,
        fullName: [out.firstName, out.lastName].filter(l => l.length).join(' '),
      }
    }).sort((a ,b) => {
      return a.lastName.localeCompare(b.lastName);
    }),
  });
}
