/**
 * @module redux/reducers
 * @description Connect all reducers
 */

import { combineReducers } from "redux";

import AuthReducer from "../reducers/AuthReducer";
import FilesReducer from "../reducers/FilesReducer";
import CompaniesReducer from "../reducers/CompaniesReducer";
import UsersReducer from "../reducers/UsersReducer";
import SnackbarReducer from "../reducers/SnackbarReducer";
import * as FilesDuck from "../ducks/files.duck";

import { AUTH_LOGOUT } from "../actions/AuthActions";

const reducers = {
  auth: AuthReducer,
  files: FilesReducer,
  companies: CompaniesReducer,
  users: UsersReducer,
  snackbar: SnackbarReducer,
  [FilesDuck.NAMESPACE]: FilesDuck.default,
};

const appReducer = combineReducers({
  ...reducers,
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default () => rootReducer;
